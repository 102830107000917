import React from "react"
import { Link } from 'react-router-dom';
import {Dot} from "../utils/CommonFunction";

export default (props) => {
  return (
      <React.Fragment>
        {/* Product */}
        <div className="col-xl-6">
          <div className="product-list-item d-flex">
            {/*<div className="product-list-item__tag">Sale</div>*/}
            {/* Product images */}
            <div className="product-list-item__images">
              {/* Product image */}
              <div className="product-list-item__image active">
                <Link to={'/product/detail/' + props?.id }>
                  <img alt="Image" data-sizes="auto" src={props?.image_url} />
                </Link>
              </div>
              {/* End product image */}
            </div>
            {/* End product images */}
            {/* Product right */}
            <div className="product-list-item__right">
              {/* Product name */}
              <div className="product-list-item__name">
                <Link to={'/product/detail/' + props?.id }>{props?.name}</Link>
              </div>
              {/* End product name */}
              {/* Product price */}
              <div className="product-list-item__price">
                <React.Fragment>
                  <span>{Dot(props?.price)}원 ~</span>
                </React.Fragment>
              </div>
              {/* End product price */}
              {/* Description */}
              <div className="product-list-item__description">
                {props?.short_contents}
              </div>
              {/* End description */}
            </div>
            {/* End product right */}
          </div>
        </div>
        {/* End product */}
      </React.Fragment>
  )
}