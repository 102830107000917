import React from "react"
import { Link } from 'react-router-dom';
import useSWR from "swr";

export default (props) => {
    const { data, mutate } = useSWR(`/logo`);

    console.log()

    return (
        <React.Fragment>
            {/* Logo */}
            <h1 className="header__logo">
                <Link to="/main">
                    {data?.logo?.image_url ? (<img src={data?.logo?.image_url} className="logo__image"/>) : ('OUIMER')}
                </Link>
            </h1>
            {/* End logo */}
        </React.Fragment>
    )
}