export const checkPhonenumber = (number) => {
  // '-' 입력 시
  var regExp = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/
  // 숫자만 입력시
  var regExp2 = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/

  // 형식에 맞는 경우 true 리턴
  return regExp.test(number)
}

export const checkEmail = (email) => {
  const regExp = /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

  // 형식에 맞는 경우 true 리턴
  return regExp.test(email)
}

export const Dot = (value) => {
  let el = (value ? value : 0).toString();
  return el.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const NumberChange = (value) => {
  return value.replace(/[^0-9]/gi,'')
};

export const EnglishChange = (value) => {
  return value.replace(/[^A-Za-z]/ig, '')
};

export const English4Change = (value) => {
  return value.replace(/[^0-9A-Za-z!?]/ig, '')
};

export const EnglishSpaceChange = (value) => {
  return value.replace(/[^0-9A-Za-z ~!@#$%&*_=+<>()[\]\-./?]/ig, '')
};