import React, { useEffect, useState } from 'react';
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import useSWR from "swr";
import {Dot, NumberChange} from "../../utils/CommonFunction";
import DaumPostCode from "react-daum-postcode";
import {api} from "../../utils/api";
import auth from "../../utils/auth";
import { motion } from "framer-motion";

export default (props) => {
    const params = new URLSearchParams(window.location.search);
    let cartIds = params.get("ids");
    let coupon = params.get("coupon");

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [recipientName, setRecipientName] = useState("");
    const [recipientPhone, setRecipientPhone] = useState("");
    const [postcode, setPostcode] = useState("");
    const [address, setAddress] = useState("");
    const [addressDetail, setAddressDetail] = useState("");
    const [point, setPoint] = useState("");
    const [deliveryMessage, setDeliveryMessage] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const [couponId, setCouponId] = useState("");
    const [isPresent, setIsPresent] = useState(false);

    const [modal, setModal] = useState(false);

    const { data: cartData, mutate } = useSWR(`/cart?is_payment=1&ids[]=${cartIds?.split(',').join("&ids[]=")}&coupon_id=${couponId}`);
    const { data: couponData } = useSWR(`/coupon`);
    const { data: pointData } = useSWR(`/point/available`);

    const carts = cartData?.carts;
    const totalPrice = cartData?.total_price;
    const paymentTotalPrice = cartData?.payment_total_price;
    const deliveryFee = cartData?.delivery_fee;

    const coupons = couponData?.coupons;
    const userPoint = pointData?.user_point;

    const content = {
        animate: {
            transition: { staggerChildren: 0.1 },
        },
    };

    const inputs = {
        initial: { y: -20, opacity: 0 },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.7,
                ease: [0.6, -0.05, 0.01, 0.99],
            },
        },
    };

    useEffect(() => {
        if (cartData?.address) {
            setName(cartData?.address?.name)
            setPhone(cartData?.address?.phone)
            setPostcode(cartData?.address?.postcode)
            setAddress(cartData?.address?.address)
            setAddressDetail(cartData?.address?.address_detail)
            setDeliveryMessage(cartData?.address?.delivery_message)
        }
    }, [cartData]);

    useEffect(() => {
        if (coupon) {
            setCouponId(coupon)
        }
    }, [coupon]);

    useEffect(() => {
        if (phone.length === 10 || phone.length === 11) {
            setPhone(phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
        }
        if (phone.length === 13) {
            setPhone(phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
        }
    }, [phone]);

    useEffect(() => {
        if (recipientPhone.length === 10 || recipientPhone.length === 11) {
            setRecipientPhone(recipientPhone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
        }
        if (recipientPhone.length === 13) {
            setRecipientPhone(recipientPhone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
        }
    }, [recipientPhone]);

    // 주소 검색
    const handlePost = async (data) => {
        setPostcode(data?.zonecode)
        setAddress(data?.roadAddress)

        setModal(false)
    };

    const handleSubmit = async () => {
        if (paymentMethod === "") {
            window.alert("필수 항목을 입력해주세요");
            return false;
        }

        if (isPresent === false) {
            if (name === "" ||
                phone === "" ||
                postcode === "" ||
                address === "" ||
                addressDetail === "") {
                window.alert("필수 항목을 입력해주세요");
                return false;
            }
        } else {
            if (recipientName === "" ||
                recipientPhone === "") {
                window.alert("필수 항목을 입력해주세요");
                return false;
            }
        }

        const formdata = new FormData();
        cartIds?.split(',')?.map((cartId) => {
            formdata.append("cart_ids[]", cartId);
        })
        if (isPresent === false) {
            formdata.append("name", name);
            formdata.append("phone", phone);
            formdata.append("postcode", postcode);
            formdata.append("address", address);
            formdata.append("address_detail", addressDetail);
            formdata.append("delivery_message", deliveryMessage);
        } else {
            formdata.append("recipient_name", recipientName);
            formdata.append("recipient_phone", recipientPhone);
        }
        formdata.append("coupon_id", couponId);
        formdata.append("point", point);
        formdata.append("payment_method", paymentMethod);
        formdata.append("is_present", isPresent ? 1 : 0);

        const {
            data: { success, alert, data },
        } = await api.post("/order/register", formdata);

        if (success) {
            window.location.replace(process.env.REACT_APP_API_URL + `/order/payment?id=${data?.order_id}&access_token=${auth.getToken()}`);
        } else {
            window.alert(alert);
        }
    };

    return (
        <React.Fragment>
            <Header />
            <motion.section
                exit={{opacity: 0}}
                className="text-gray-700 body-font relative"
            >
                <motion.div variants={content} animate="animate" initial="initial">
                    {/* Checkout */}
                    <div className="checkout">
                        {/* Container */}
                        <div className="container container--type-3">
                            {/* Second container */}
                            <div className="container">
                                {/* Title */}
                                <motion.div variants={inputs}>
                                    <h1 className="checkout__title">주문하기</h1>
                                </motion.div>
                                {/* End title */}
                                {/* Form */}
                                <form className="row">
                                    {/* Left column */}
                                    <div className="col-lg-7">
                                        {/* Container */}
                                        <div className="checkout__container">
                                            {/* Billing form */}
                                            <div className="billing-form">
                                                {/* Title */}
                                                <motion.div variants={inputs}>
                                                    <h3 className="billing-form__heading">결제 정보</h3>
                                                </motion.div>
                                                {/* End title */}
                                                {/* Form checkbox */}
                                                <motion.div variants={inputs}>
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" id="isPresent" className="form-checkbox__input" checked={isPresent} onClick={() => {
                                                            setIsPresent(!isPresent);
                                                            setRecipientName('');
                                                            setRecipientPhone('');
                                                            setPostcode('');
                                                            setAddress('');
                                                            setAddressDetail('')
                                                            setDeliveryMessage('')
                                                        }} />
                                                        <label className="form-checkbox__label" htmlFor="isPresent">지인에게 선물하기 (주소를 모르는 경우에 체크)</label>
                                                    </div>
                                                    {/* End form checkbox */}
                                                    {isPresent === false && (
                                                        <>
                                                            {/* Form group */}
                                                            <div className="form-group">
                                                                <input type="text" placeholder="이름" value={name} className="form-group__input" onChange={(e) => setName(e?.target?.value)} />
                                                            </div>
                                                            {/* End form group */}
                                                            {/* Form group */}
                                                            <div className="form-group">
                                                                <input type="text" placeholder="연락처" value={phone} className="form-group__input" onChange={(e) => {
                                                                    const regex = /^[0-9\b -]{0,13}$/;
                                                                    if (regex.test(e.target.value)) {
                                                                        setPhone(e.target.value);
                                                                    }
                                                                }} />
                                                            </div>
                                                            {/* End form group */}
                                                        </>
                                                    )}
                                                    {isPresent === true && (
                                                        <>
                                                            {/* Form group */}
                                                            <div className="form-group">
                                                                <input type="text" placeholder="수신인명" value={recipientName} className="form-group__input" onChange={(e) => setRecipientName(e?.target?.value)} />
                                                            </div>
                                                            {/* End form group */}
                                                            {/* Form group */}
                                                            <div className="form-group">
                                                                <input type="text" placeholder="수신인 연락처" value={recipientPhone} className="form-group__input" onChange={(e) => {
                                                                    const regex = /^[0-9\b -]{0,13}$/;
                                                                    if (regex.test(e.target.value)) {
                                                                        setRecipientPhone(e.target.value);
                                                                    }
                                                                }} />
                                                            </div>
                                                            {/* End form group */}
                                                        </>
                                                    )}
                                                    {isPresent === false && (
                                                        <>
                                                            {/* Row */}
                                                            <div className="row">
                                                                <div className="col-12 col-md-9">
                                                                    {/* Form group */}
                                                                    <div className="form-group">
                                                                        <input type="text" placeholder="우편번호" value={postcode} className="form-group__input" readOnly={true} />
                                                                    </div>
                                                                    {/* End form group */}
                                                                </div>
                                                                <div className="col-12 col-md-3">
                                                                    {/* Form group */}
                                                                    <div className="form-group">
                                                                        <input type="button" className="form-group__button_search" value="주소 검색" onClick={() => { setModal(true); } } />
                                                                    </div>
                                                                    {/* End form group */}
                                                                </div>
                                                            </div>
                                                            {/* End row */}
                                                            {/* Form group */}
                                                            <div className="form-group">
                                                                <input type="text" placeholder="기본 주소" value={address} className="form-group__input" readOnly={true} />
                                                            </div>
                                                            {/* End form group */}
                                                            {/* Form group */}
                                                            <div className="form-group">
                                                                <input type="text" placeholder="상세 주소" value={addressDetail} className="form-group__input" onChange={(e) => setAddressDetail(e?.target?.value)} />
                                                            </div>
                                                            {/* End form group */}
                                                            {/* Form group */}
                                                            <div className="form-group">
                                                                <input type="text" placeholder="요청사항" value={deliveryMessage} className="form-group__input" onChange={(e) => setDeliveryMessage(e?.target?.value)} />
                                                            </div>
                                                            {/* End form group */}
                                                        </>
                                                    )}
                                                    {/* Form group */}
                                                    <div className="form-group">
                                                        <select className="form-group__input" value={couponId} onChange={(value) => setCouponId(value?.target?.value)}>
                                                            {coupons?.length > 0 ? (
                                                                <>
                                                                    <option>쿠폰 선택</option>
                                                                    {coupons?.map((coupon) => (
                                                                        <option value={coupon?.id}>{coupon?.name} ({coupon?.value}{coupon?.type === 1 ? '% 할인' : (coupon?.type === 2 ? '원 할인' : '')})</option>
                                                                    ))}
                                                                </>
                                                            ) : (<option>보유중인 쿠폰이 없습니다</option>)}
                                                        </select>
                                                    </div>
                                                    {/* End form group */}
                                                    {/* Form group */}
                                                    <div className="form-group">
                                                        <input type="text" value={point} placeholder={`적립금 사용 (최대 ${userPoint}P 사용 가능)`} className="form-group__input" onChange={(e) => {
                                                            var point = NumberChange(e?.target?.value)
                                                            if (parseInt(point) > parseInt(userPoint)) {
                                                                setPoint(userPoint)
                                                            } else {
                                                                setPoint(point)
                                                            }
                                                        }} />
                                                    </div>
                                                    {/* End form group */}
                                                    {/* Form radio */}
                                                    <div className="form-radio">
                                                        <input type="radio" id="paymentMethodCard" className="form-radio__input" checked={paymentMethod === 'card'} onClick={() => setPaymentMethod('card')} />
                                                        <label className="form-radio__label" htmlFor="paymentMethodCard">신용카드</label>

                                                        <input type="radio" id="paymentMethodBank" className="form-radio__input" checked={paymentMethod === 'bank'} onClick={() => setPaymentMethod('bank')} />
                                                        <label className="form-radio__label" htmlFor="paymentMethodBank">계좌이체</label>

                                                        <input type="radio" id="paymentMethodVbank" className="form-radio__input" checked={paymentMethod === 'vbank'} onClick={() => setPaymentMethod('vbank')} />
                                                        <label className="form-radio__label" htmlFor="paymentMethodVbank">가상계좌</label>
                                                    </div>
                                                </motion.div>
                                                {/* End form radio */}
                                            </div>
                                            {/* End billing form */}
                                        </div>
                                        {/* End container */}
                                    </div>
                                    {/* End left column */}
                                    {/* Right column */}
                                    <div className="col-lg-5">
                                        <motion.div variants={inputs}>
                                            {/* Summary */}
                                            <div className="checkout__summary">
                                                {/* Title -*/}
                                                <h3 className="checkout-summary__title">상품 목록</h3>
                                                {/* End title */}
                                                {/* Products */}
                                                <div className="checkout__products">
                                                    {cartData?.carts?.map((cart) => (
                                                        <div className="checkout__product">
                                                            <div className="checkout-product__image">
                                                                <a href={`/product/detail/${cart?.product_id}`}>
                                                                    <img alt="Image" data-sizes="auto" src={cart?.image_url} />
                                                                </a>
                                                            </div>
                                                            <div className="checkout-product__title-and-variant">
                                                                <h3 className="checkout-product__title"><a href={`/product/detail/${cart?.product_id}`}>{cart?.name}</a></h3>
                                                                <div className="checkout-product__variant">
                                                                    {cart?.option_name ? (<>옵션명 : {cart?.option_name} <br/></>) : ''}
                                                                    {cart?.bias_color_name ? (<>바이어스 컬러 : {cart?.bias_color_name} <br/></>) : ''}
                                                                    {cart?.use_personal === 1 && (<>텍스트 컬러 : {cart?.text_color_name} / 폰트 : {cart?.font} / 문구 : {cart?.message}</>)}
                                                                </div>
                                                            </div>
                                                            <div className="checkout-product__price">{Dot(cart?.total_price) + '원'}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {/* End products */}
                                                {/* Subtotal and shipping */}
                                                <div className="checkout__subtotal-and-shipping">
                                                    {/* Subtotal */}
                                                    <div className="checkout__subtotal">
                                                        <div className="checkout-subtotal__title">총 금액</div>
                                                        <div className="checkout-subtotal__price">{Dot(totalPrice)}원</div>
                                                    </div>
                                                    {/* End subtotal */}
                                                    {/* Shipping */}
                                                    <div className="checkout__shipping">
                                                        <div className="checkout-shipping__title">배송비</div>
                                                        <div className="checkout-shipping__price">{Dot(paymentTotalPrice >= 50000 ? 0 : deliveryFee)}원</div>
                                                    </div>
                                                    {/* End shipping */}
                                                </div>
                                                {/* End subtotal and shipping */}
                                                {/* Total */}
                                                <div className="checkout__total">
                                                    <div className="checkout-total__title">결제 금액</div>
                                                    <div className="checkout-total__price">{Dot(paymentTotalPrice - point)}원</div>
                                                </div>
                                                {/* End total */}
                                                {/* Privacy policy */}
                                                {/*<div className="checkout__privacy-policy">
                                        여기에는 내용을 넣을 수 있구요 <a href="#">링크도 가능해요</a>.
                                    </div>*/}
                                                {/* End privacy policy */}
                                                {/* Action */}
                                                <div className="checkout__action">
                                                    <button type="button" className="second-button" onClick={() => handleSubmit()}>결제하기</button>
                                                </div>
                                                {/* End action */}
                                            </div>
                                            {/* End summary */}
                                        </motion.div>
                                    </div>
                                    {/* End right column */}
                                </form>
                                {/* End form */}
                            </div>
                            {/* End second container */}
                        </div>
                        {/* End container */}
                    </div>
                </motion.div>
            </motion.section>
            {/* End checkout */}
            <Footer />
            {modal && (
                <div className="popup__wrap">
                    <div className="popup">
                        <DaumPostCode
                            style={{
                                width: "100%",
                                height: "100%",
                                marginTop: 70,
                            }}
                            onComplete={handlePost}
                        />
                    </div>
                    <div className="popup__background" onClick={() => setModal(false)} />
                </div>
            )}
        </React.Fragment>
    )
}