import React, {useEffect, useState} from 'react';
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import useSWR from "swr";
import { Dot } from "../../utils/CommonFunction";
import { api } from "../../utils/api";
import { motion } from "framer-motion";

export default (props) => {
    const [cartIds, setCartIds] = useState([]);
    const [couponId, setCouponId] = useState("");

    const { data: cartData, mutate } = useSWR(`/cart?coupon_id=${couponId}`);
    const { data: couponData } = useSWR(`/coupon`);

    const carts = cartData?.carts;
    const totalPrice = cartData?.total_price;
    const paymentTotalPrice = cartData?.payment_total_price;
    const deliveryFee = cartData?.delivery_fee;

    const coupons = couponData?.coupons;

    useEffect(() => {
        let ids = []
        carts?.map((cart) => {
            ids.push(cart?.id)
        })

        setCartIds(ids)
    }, [carts]);

    const content = {
        animate: {
            transition: { staggerChildren: 0.1 },
        },
    };

    const inputs = {
        initial: { y: -20, opacity: 0 },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.7,
                ease: [0.6, -0.05, 0.01, 0.99],
            },
        },
    };

    return (
        <React.Fragment>
            <Header />
            <motion.section
                exit={{opacity: 0}}
                className="text-gray-700 body-font relative"
            >
                {/* Shopping cart */}
                <div className="shopping-cart">
                    {/* Container */}
                    <div className="container container--type-3">
                        {/* Second container */}
                        <div className="container">
                            <motion.div variants={content} animate="animate" initial="initial">
                                {/* Title */}
                                <motion.div variants={inputs}>
                                    <h1 className="shopping-cart__title">장바구니</h1>
                                </motion.div>
                                {/* End title */}
                                {/* Row */}
                                <motion.div variants={inputs}>
                                    <div className="row">
                                        {/* Left */}
                                        <div className="col-lg-7 col-xl-8">
                                            {/* Cart container */}
                                            <div className="shopping-cart__container">
                                                {/*- Table responsive */}
                                                <div className="table-responsive">
                                                    {/* Table */}
                                                    <table className="shopping-cart__table">
                                                        <thead>
                                                        <tr>
                                                            <th>상품</th>
                                                            <th>가격</th>
                                                            <th>수량</th>
                                                            <th>총 금액</th>
                                                            <th />
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {carts?.map((cart) => (
                                                            <tr>
                                                                <td>
                                                                    <div className="shopping-cart__product">
                                                                        <div className="cart-product__image">
                                                                            <a href={`/product/detail/${cart?.product_id}`}>
                                                                                {cart?.use_personal === 1 ? (
                                                                                    <div className="mobile-product-image" key={0}>
                                                                                        <img src={cart?.image_url} />
                                                                                        <div className={'product_preview ' + cart?.font}>
                                                                                            <span className={cart?.font + '_size'} style={{ color: `${cart?.text_color_code}`, width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                                {[...cart?.message]?.map((message_, index) =>
                                                                                                    cart?.font === 'doha' ? (<span>{[...cart?.message]?.length === 3 ? message_ : ''}</span>)
                                                                                                        : cart?.font === 'marrakech' ? (<span className={[...cart?.message]?.length === 3 ? (index === 1 ? 'large' : 'small') : ('large')}>{message_}</span>)
                                                                                                            : cart?.font === 'oslo' ? (<span className={([...cart?.message]?.length === 1 ? ('message' + [...cart?.message]?.length) : (index !== 0 ? ('message' + [...cart?.message]?.length) : ''))}>{message_}</span>)
                                                                                                                : (cart?.font === 'paris' || cart?.font === 'seoul' || cart?.font === 'miami' || cart?.font === 'berlin') ? (<span className={'message' + [...cart?.message]?.length}>{message_}</span>) : (<span>{message_}</span>)
                                                                                                )}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (<img src={cart?.image_url} />)}
                                                                            </a>
                                                                        </div>
                                                                        <div className="cart-product__title-and-variant">
                                                                            <h3 className="cart-product__title"><a href={`/product/detail/${cart?.product_id}`}>{cart?.name}</a></h3>
                                                                            <div className="cart-product__variant">
                                                                                {cart?.option_name ? (<>옵션명 : {cart?.option_name} <br/></>) : ''}
                                                                                {cart?.bias_color_name ? (<>바이어스 컬러 : {cart?.bias_color_name} <br/></>) : ''}
                                                                                {cart?.use_personal === 1 && (<>텍스트 컬러 : {cart?.text_color_name} / 폰트 : {cart?.font} / 문구 : {cart?.message}</>)}
                                                                            </div>
                                                                            <div className="cart-product__action"><a href={`/product/detail/${cart?.product_id}?cart_id=${cart?.id}`}>Edit</a></div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="cart-product__price">
                                                                        {Dot(cart?.price + cart?.product_bias_color_price + cart?.product_text_color_price + cart?.option_price) + '원'}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="cart-product__quantity-field">
                                                                        <div className="quantity-field__minus">
                                                                            <a onClick={() => {
                                                                                const formData = new FormData();
                                                                                formData.append("cart_id", cart?.id);
                                                                                formData.append("amount", cart?.amount - 1);

                                                                                api.post('/cart/amount', formData)
                                                                                    .then((response) => {
                                                                                        if (response.data.success) {
                                                                                            mutate();
                                                                                        } else {
                                                                                            window.alert(response.data.alert);
                                                                                        }
                                                                                    });
                                                                            }}>-</a>
                                                                        </div>
                                                                        <input type="text" value={cart?.amount} className="quantity-field__input" />
                                                                        <div className="quantity-field__plus">
                                                                            <a onClick={() => {
                                                                                const formData = new FormData();
                                                                                formData.append("cart_id", cart?.id);
                                                                                formData.append("amount", cart?.amount + 1);

                                                                                api.post('/cart/amount', formData)
                                                                                    .then((response) => {
                                                                                        if (response.data.success) {
                                                                                            mutate();
                                                                                        } else {
                                                                                            window.alert(response.data.alert);
                                                                                        }
                                                                                    });
                                                                            }}>+</a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="cart-product__price">
                                                                        {Dot(cart?.total_price) + '원'}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="cart-product__delete">
                                                                        <a onClick={() => {
                                                                            api.delete('/cart/delete', {
                                                                                params: {
                                                                                    cart_id: cart?.id,
                                                                                },
                                                                            })
                                                                                .then((response) => {
                                                                                    if (response.data.success) {
                                                                                        mutate();
                                                                                    } else {
                                                                                        window.alert(response.data.alert);
                                                                                    }
                                                                                });
                                                                        }}>
                                                                            <i className="lnil lnil-close" />
                                                                        </a>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                    {/* End table */}
                                                </div>
                                                {/* End table responsive */}
                                            </div>
                                            {/* End cart container */}
                                        </div>
                                        {/* End left */}
                                        {/* Right */}
                                        <div className="col-lg-5 col-xl-4">
                                            {/* Order summary */}
                                            <div className="shopping-cart__order-summary">
                                                {/* Background */}
                                                <div className="order-summary__background">
                                                    {/* Title */}
                                                    <h3 className="order-summary__title">주문서</h3>
                                                    {/* End title */}
                                                    {/* Subtotal */}
                                                    <div className="order-summary__subtotal">
                                                        <div className="summary-subtotal__title">총 금액</div>
                                                        <div className="summary-subtotal__price">{Dot(totalPrice)}원</div>
                                                    </div>
                                                    {/* End subtotal */}
                                                    {/* Shipping */}
                                                    <div className="checkout__shipping">
                                                        <div className="checkout-shipping__title">배송비</div>
                                                        <div className="checkout-shipping__price">{Dot(deliveryFee)}원</div>
                                                    </div>
                                                    {/* End shipping */}
                                                    {/* Delivery method */}
                                                    <div className="order-summary__delivery-method">
                                                        <select onChange={(value) => setCouponId(value?.target?.value)}>
                                                            {coupons?.length > 0 ? (
                                                                <>
                                                                    <option>쿠폰 선택</option>
                                                                    {coupons?.map((coupon) => (
                                                                        <option value={coupon?.id}>{coupon?.name} ({coupon?.value}{coupon?.type === 1 ? '% 할인' : (coupon?.type === 2 ? '원 할인' : '')})</option>
                                                                    ))}
                                                                </>
                                                            ) : (<option>보유중인 쿠폰이 없습니다</option>)}
                                                        </select>
                                                    </div>
                                                    {/* End delivery method */}
                                                    {/* Total */}
                                                    <div className="order-summary__total">
                                                        <div className="summary-total__title">최종 구매 금액</div>
                                                        <div className="summary-total__price">{Dot(paymentTotalPrice)}원</div>
                                                    </div>
                                                    {/* End total */}
                                                    {/* Proceed to checkout */}
                                                    <div className="order-summary__proceed-to-checkout">
                                                        <a href={`/checkout?ids=${cartIds?.join(',')}&coupon=${couponId}`} className="second-button">구매하기</a>
                                                    </div>
                                                    {/* End proceed to checkout */}
                                                </div>
                                                {/* End background */}
                                                {/* Action */}
                                                <div className="order-summary__action">
                                                    <a href="/product/index">계속 쇼핑하기</a>
                                                </div>
                                                {/* End action */}
                                            </div>
                                            {/* End order summary */}
                                        </div>
                                        {/* End right */}
                                    </div>
                                </motion.div>
                                {/* End row */}
                            </motion.div>
                        </div>
                        {/* End second container */}
                    </div>
                    {/* End container */}
                </div>
            </motion.section>
            {/* End shopping cart */}
            <Footer />
        </React.Fragment>
    )
}