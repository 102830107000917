import React, { useEffect, useState } from 'react';
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import { api } from "../../utils/api";
import { checkPhonenumber, checkEmail } from "../../utils/CommonFunction";
import useSWR from "swr";
import { motion } from "framer-motion";

export default (props) => {
  const { data, mutate } = useSWR(`/mypage`);
  const user = data?.user_info;

  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const content = {
    animate: {
      transition: { staggerChildren: 0.1 },
    },
  };

  const inputs = {
    initial: { y: -20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  useEffect(() => {
    setName(user?.name)
    setPhone(user?.phone)
  }, [user]);

  useEffect(() => {
    if (phone?.length === 10 || phone?.length === 11) {
      setPhone(phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
    }
    if (phone?.length === 13) {
      setPhone(phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    }
  }, [phone]);

  const handleSubmit = async () => {
    if (password === "" || phone === "") {
      window.alert("필수 항목을 입력해주세요");
      return false;
    }

    if (!checkPhonenumber(phone)) {
      window.alert("휴대폰 번호 형식을 재확인 해주세요");
      return false;
    }

    const formdata = new FormData();
    formdata.append("password", password);
    formdata.append("phone", phone);

    const {
      data: { success, alert, data },
    } = await api.post("/mypage/modify", formdata);

    if (success) {
      window.alert("변경되었습니다");
    } else {
      window.alert(alert);
    }
  };

  return (
      <React.Fragment>
        <Header homepage="true" />
        <motion.section
            exit={{opacity: 0}}
            className="text-gray-700 body-font relative"
        >
          {/* Login */}
          <div className="login">
            {/* Container */}
            <div className="container container--type-2">
              {/* Container */}
              <div className="container">
                {/* Login container */}
                <div className="login__container">
                  {/* Login d-flex */}
                  <div className="login__d-flex d-flex">
                    {/* Login left */}
                    <div className="login__full">
                      <motion.div variants={content} animate="animate" initial="initial">
                        {/* Login box */}
                        <div className="login__box active js-login-in">
                          <motion.div variants={inputs}>
                            {/* Login heading */}
                            <h4 className="login__h4">내 정보</h4>
                            {/* End login heading */}
                          </motion.div>
                          {/* Form */}
                          <form>
                            <motion.div variants={inputs}>
                              {/* Form group */}
                              <div className="form-group required">
                                <input type="text" required="required" name="email" placeholder="Email" className="form-group__input" value={user?.email} disabled={true} />
                              </div>
                              {/* End form group */}
                              {/* Form group */}
                              <div className="form-group required">
                                <input type="password" required="required" name="password" placeholder="Password" className="form-group__input" value={password} onChange={(e) => setPassword(e.target.value)} />
                              </div>
                              {/* End form group */}
                              {/* Form group */}
                              <div className="form-group required">
                                <input type="text" required="required" name="name" placeholder="Name" className="form-group__input" disabled={true} value={name} onChange={(e) => setName(e.target.value)} />
                              </div>
                              {/* End form group */}
                              {/* Form group */}
                              <div className="form-group required">
                                <input type="text" required="required" name="phone" placeholder="Phone" className="form-group__input" value={phone} onChange={(e) => {
                                  const regex = /^[0-9\b -]{0,13}$/;
                                  if (regex.test(e.target.value)) {
                                    setPhone(e.target.value);
                                  }
                                }} />
                              </div>
                              {/* End form group */}
                            </motion.div>
                            <motion.div variants={inputs}>
                              {/* Action */}
                              <div className="login__action"><input className="second-button" type="button" value="수정" onClick={handleSubmit} /></div>
                              {/* End action */}
                              {/* Action */}
                              <div className="login__action"><input className="second-button" type="button" value="주문내역 보기" onClick={() => window.location.href = '/order/index'} /></div>
                              {/* End action */}
                            </motion.div>
                          </form>
                          {/* End form */}
                        </div>
                      </motion.div>
                      {/* End login box */}
                    </div>
                    {/* End login left */}
                  </div>
                  {/* End login d-flex */}
                </div>
                {/* End login container */}
              </div>
              {/* End container */}
            </div>
            {/* End container */}
          </div>
        </motion.section>
        {/* End login */}
        <Footer />
      </React.Fragment>
  )
}