import React from "react"
import { Link } from 'react-router-dom';
import { Dot } from "../utils/CommonFunction";

export default (props) => {
    return (
        <div>
            {/* Product */}
            <div className="product-grid-item">
                {/*<div className="product-grid-item__tag">Sale</div>*/}
                {/* Product images */}
                <div className="product-grid-item__images">
                    {/* Product image */}
                    <div className="product-grid-item__image active">
                        <Link to={'/product/detail/' + props?.id }>
                            <img src={props?.image_url} />
                        </Link>
                    </div>
                    {/* End product image */}
                </div>
                {/* End product images */}
                {/* Product name */}
                <div className="product-grid-item__name">
                    <Link to={'/product/detail/' + props?.id }>{props?.name}</Link>
                </div>
                {/* End product name */}
                {/* Product price */}
                <div className="product-grid-item__price">
                    {/*{props.variants[0].compareAtPrice != null ? (
            <React.Fragment>
              <span className="product-grid-item__price-new">${props?.price}</span> <span className="product-grid-item__price-old">${props.variants[0].compareAtPrice}</span>
            </React.Fragment>
          ) : (*/}
                    <React.Fragment>
                        <span>{Dot(props?.price)}원</span>
                    </React.Fragment>
                    {/*)}*/}
                </div>
                {/* End product price */}
            </div>
            {/* End Product */}
        </div>
    )
}