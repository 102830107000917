import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import useSWR from "swr";
import DaumPostCode from "react-daum-postcode";
import {api} from "../../utils/api";
import {useParams} from "react-router-dom";
import {checkPhonenumber, NumberChange} from "../../utils/CommonFunction";
import { motion } from "framer-motion";

export default (props) => {
    const history = useHistory();

    const code = useParams().code;

    const { data: orderData } = useSWR(`/order/detail?code=${code}`);

    const order = orderData?.order;
    const orderProducts = orderData?.order_products;

    const [smsCode, setSmsCode] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [postcode, setPostcode] = useState("");
    const [address, setAddress] = useState("");
    const [addressDetail, setAddressDetail] = useState("");
    const [deliveryMessage, setDeliveryMessage] = useState("");
    const [isRequest, setIsRequest] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [codeButtonDisabled, setCodeButtonDisabled] = useState(false);

    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (phone.length === 10 || phone.length === 11) {
            setPhone(phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
        }
        if (phone.length === 13) {
            setPhone(phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
        }
    }, [phone]);

    const content = {
        animate: {
            transition: { staggerChildren: 0.1 },
        },
    };

    const inputs = {
        initial: { y: -20, opacity: 0 },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.7,
                ease: [0.6, -0.05, 0.01, 0.99],
            },
        },
    };

    // 주소 검색
    const handlePost = async (data) => {
        setPostcode(data?.zonecode)
        setAddress(data?.roadAddress)

        setModal(false)
    };

    const handleCodeRequest = async () => {
        const formdata = new FormData();
        formdata.append("phone", order?.recipient_phone);

        const {
            data: { success, alert, data },
        } = await api.post("/sms/certification", formdata);

        if (success) {
            setCodeButtonDisabled(true)
            setIsRequest(true);
        } else {
            window.alert(alert);
        }
    };

    const handleCodeCheck = async () => {
        const formdata = new FormData();
        formdata.append("phone", order?.recipient_phone);
        formdata.append("code", smsCode);

        const {
            data: { success, alert, data },
        } = await api.post("/sms/certification/check", formdata);

        if (success) {
            setIsComplete(true)
            window.alert('인증 되었습니다');
        } else {
            window.alert(alert);
        }
    };

    const handleSubmit = async () => {
        if (name === "" ||
            phone === "" ||
            postcode === "" ||
            address === "" ||
            addressDetail === "") {
            window.alert("필수 항목을 입력해주세요");
            return false;
        }

        const formdata = new FormData();
        formdata.append("code", code);
        formdata.append("sms_code", smsCode);
        formdata.append("name", name);
        formdata.append("phone", phone);
        formdata.append("postcode", postcode);
        formdata.append("address", address);
        formdata.append("address_detail", addressDetail);
        formdata.append("delivery_message", deliveryMessage);

        const {
            data: { success, alert, data },
        } = await api.post("/order/present", formdata);

        if (success) {
            window.alert('입력하신 정보가 저장되었습니다.')
            history.push('/join')
        } else {
            window.alert(alert);
        }
    };

    return (
        <React.Fragment>
            <Header />
            <motion.section
                exit={{opacity: 0}}
                className="text-gray-700 body-font relative"
            >
                <motion.div variants={content} animate="animate" initial="initial">
                    {/* Checkout */}
                    <div className="checkout">
                        {/* Container */}
                        <div className="container container--type-3">
                            {/* Second container */}
                            <div className="container">
                                {/* Title */}
                                <motion.div variants={inputs}>
                                    <h1 className="checkout__title">배송지 입력하기</h1>
                                </motion.div>
                                {/* End title */}
                                {/* Form */}
                                <form className="row">
                                    {/* Left column */}
                                    <div className="col-lg-7">
                                        {/* Container */}
                                        <div className="checkout__container">
                                            <motion.div variants={inputs}>
                                                <div className="billing-form">
                                                    {/* Title */}
                                                    <h3 className="billing-form__heading">휴대폰 인증</h3>
                                                    {/* End title */}
                                                    {/* Form group */}
                                                    <div className="form-group">
                                                        <input type="text" value={order?.recipient_phone} className="form-group__input_button" disabled={true} />
                                                        <input type="button" required="required" name="request" className="form-group__button" value="인증 요청" disabled={codeButtonDisabled} onClick={() => handleCodeRequest()} />
                                                    </div>
                                                    {/* End form group */}
                                                    {/* Form group */}
                                                    {isRequest && (
                                                        <div className="form-group required">
                                                            <input type="text" required="required" name="code" placeholder="Code" className="form-group__input_button" maxLength={6} value={smsCode} onChange={(e) => { setSmsCode(NumberChange(e.target.value)); }} />
                                                            <input type="button" required="required" name="request" className="form-group__button" value="인증 완료" disabled={isComplete} onClick={() => handleCodeCheck()} />
                                                        </div>
                                                    )}
                                                    {/* End form group */}
                                                </div>
                                            </motion.div>
                                            {/* Billing form */}
                                            <motion.div variants={inputs}>
                                                <div className="billing-form">
                                                    {/* Title */}
                                                    <h3 className="billing-form__heading">배송 정보</h3>
                                                    {/* End title */}
                                                    {/* Form group */}
                                                    <div className="form-group">
                                                        <input type="text" placeholder="이름" value={name} className="form-group__input" onChange={(e) => setName(e?.target?.value)} />
                                                    </div>
                                                    {/* End form group */}
                                                    {/* Form group */}
                                                    <div className="form-group">
                                                        <input type="text" placeholder="연락처" value={phone} className="form-group__input" onChange={(e) => {
                                                            const regex = /^[0-9\b -]{0,13}$/;
                                                            if (regex.test(e.target.value)) {
                                                                setPhone(e.target.value);
                                                            }
                                                        }} />
                                                    </div>
                                                    {/* End form group */}
                                                    {/* Row */}
                                                    <div className="row">
                                                        <div className="col-12 col-md-9">
                                                            {/* Form group */}
                                                            <div className="form-group">
                                                                <input type="text" placeholder="우편번호" value={postcode} className="form-group__input" readOnly={true} />
                                                            </div>
                                                            {/* End form group */}
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            {/* Form group */}
                                                            <div className="form-group">
                                                                <input type="button" className="form-group__button_search" value="주소 검색" onClick={() => { setModal(true); } } />
                                                            </div>
                                                            {/* End form group */}
                                                        </div>
                                                    </div>
                                                    {/* End row */}
                                                    {/* Form group */}
                                                    <div className="form-group">
                                                        <input type="text" placeholder="기본 주소" value={address} className="form-group__input" readOnly={true} />
                                                    </div>
                                                    {/* End form group */}
                                                    {/* Form group */}
                                                    <div className="form-group">
                                                        <input type="text" placeholder="상세 주소" value={addressDetail} className="form-group__input" onChange={(e) => setAddressDetail(e?.target?.value)} />
                                                    </div>
                                                    {/* End form group */}
                                                    {/* Form group */}
                                                    <div className="form-group">
                                                        <input type="text" placeholder="요청사항" value={deliveryMessage} className="form-group__input" onChange={(e) => setDeliveryMessage(e?.target?.value)} />
                                                    </div>
                                                    {/* End form group */}
                                                </div>
                                            </motion.div>
                                            {/* End billing form */}
                                        </div>
                                        {/* End container */}
                                    </div>
                                    {/* End left column */}
                                    {/* Right column */}
                                    <div className="col-lg-5">
                                        <motion.div variants={inputs}>
                                            {/* Summary */}
                                            <div className="checkout__summary">
                                                {/* Title -*/}
                                                <h3 className="checkout-summary__title">상품 목록</h3>
                                                {/* End title */}
                                                {/* Products */}
                                                <div className="checkout__products">
                                                    {orderProducts?.map((orderProduct) => (
                                                        <div className="checkout__product">
                                                            <div className="checkout-product__image">
                                                                <a href={`/product/detail/${orderProduct?.product_id}`}>
                                                                    <img alt="Image" src={orderProduct?.image_url} />
                                                                </a>
                                                            </div>
                                                            <div className="checkout-product__title-and-variant">
                                                                <h3 className="checkout-product__title"><a href={`/product/detail/${orderProduct?.product_id}`}>{orderProduct?.name}</a></h3>
                                                                <div className="checkout-product__variant">{orderProduct?.message ? orderProduct?.message : '-'}</div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {/* End products */}
                                                {/* Privacy policy */}
                                                {/*<div className="checkout__privacy-policy">
                                        여기에는 내용을 넣을 수 있구요 <a href="#">링크도 가능해요</a>.
                                    </div>*/}
                                                {/* End privacy policy */}
                                                {/* Action */}
                                                <div className="checkout__action">
                                                    <button type="button" className="second-button" onClick={() => handleSubmit()}>저장하기</button>
                                                </div>
                                                {/* End action */}
                                            </div>
                                        </motion.div>
                                        {/* End summary */}
                                    </div>
                                    {/* End right column */}
                                </form>
                                {/* End form */}
                            </div>
                            {/* End second container */}
                        </div>
                        {/* End container */}
                    </div>
                </motion.div>
            </motion.section>
            {/* End checkout */}
            <Footer />
            {modal && (
                <div className="popup__wrap">
                    <div className="popup">
                        <DaumPostCode
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                            onComplete={handlePost}
                        />
                    </div>
                    <div className="popup__background" onClick={() => setModal(false)} />
                </div>
            )}
        </React.Fragment>
    )
}