import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import auth from "../utils/auth";

export default (props) => {
  const [mobileMenu, setMobileMenu] = useState(false)
  function handleMobileMenu() {
    if(mobileMenu === false) {
      setMobileMenu(true);
    } else {
      setMobileMenu(false);
    }
  }
  return (
      <React.Fragment>
        {/* Mobile menu */}
        <div className="header__mobile-menu">
          {/* Open mobile menu */}
          <div className="mobile-menu__open">
            <a onClick={(e) => handleMobileMenu()}><i className="lnil lnil-menu" /></a>
          </div>
          {/* End open mobile menu */}
          {/* Mobile menu */}
          <div className={mobileMenu === true ? 'mobile-menu active': 'mobile-menu' }>
            {/* Overlay */}
            <div className="mobile-menu__overlay" onClick={(e) => handleMobileMenu()} />
            {/* End overlay */}
            {/* Content */}
            <div className="mobile-menu__content">
              {/* Close mobile menu */}
              <div className="mobile-menu__close">
                <a onClick={(e) => handleMobileMenu()}><i className="lnil lnil-close" /></a>
              </div>
              {/* End close mobile menu */}
              {/* Mobile logo */}
              <h3 className="mobile-menu__logo">OUIMER</h3>
              {/* End mobile logo */}
              {/* Mobile Nav */}
              <ul className="mobile-menu__nav">
                <li>
                  <Link to="/main">MAIN</Link>
                </li>
                <li>
                  <Link to="/product/index">ALL</Link>
                </li>
                {props?.product_categories?.map((product_category) => (
                    <li>
                      <Link to={`/product/index?category_id=${product_category?.id}`}>{product_category?.category}</Link>
                    </li>
                ))}
              </ul>
              {/* End Mobile nav */}
              <div className="mobile-menu__footer">
                <i className="lnil lnil-user" />
                { auth.getToken() ? (
                    <>
                      <Link to="/mypage">내 정보</Link>
                      <Link to="/order/index">주문 정보</Link>
                      <Link to="" onClick={() => { auth.clearAppStorage(); window.location.reload(); }}>로그아웃</Link>
                    </>
                ) : (
                    <>
                      <Link to="/login">로그인</Link>
                      <Link to="/join">회원가입</Link>
                    </>
                )}
              </div>
            </div>
            {/* End content */}
          </div>
          {/* End mobile menu */}
        </div>
        {/* End mobile menu */}
      </React.Fragment>
  )
}