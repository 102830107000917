import React from 'react';
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import { useParams } from 'react-router-dom';
import useSWR from "swr";
import moment from "moment";
import { motion } from "framer-motion";

export default (props) => {
    const id = useParams().id;

    const { data, mutate } = useSWR(`/notice/detail?id=${id}`);
    const notice = data?.notice;

    const content = {
        animate: {
            transition: { staggerChildren: 0.1 },
        },
    };

    const inputs = {
        initial: { y: -20, opacity: 0 },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.7,
                ease: [0.6, -0.05, 0.01, 0.99],
            },
        },
    };

    return (
        <React.Fragment>
            <Header />
            <motion.section
                exit={{opacity: 0}}
                className="text-gray-700 body-font relative"
            >
                <motion.div variants={content} animate="animate" initial="initial">
                    {/* Post */}
                    <div className="post">
                        {/* Post heading */}
                        <div className="post__heading">
                            {/* Container */}
                            <div className="container container--type-3">
                                {/* Post meta */}
                                <motion.div variants={inputs}>
                                    <ul className="post__meta">
                                        <li>{moment(notice?.created_at).format('YYYY-MM-DD')}</li>
                                    </ul>
                                </motion.div>
                                {/* End post meta */}
                                {/* Post title */}
                                <motion.div variants={inputs}>
                                    <h1 className="post__title">{notice?.title}</h1>
                                </motion.div>
                                {/* End post title */}
                            </div>
                            {/* End container */}
                        </div>
                        {/* End post heading */}
                        {/* Post image */}
                        <motion.div variants={inputs}>
                            <div className="post__image">
                                {/* Container */}
                                <div className="container container--type-3">
                                    <img src={notice?.thumbnail_image_url} />
                                </div>
                                {/* End container */}
                            </div>
                        </motion.div>
                        {/* End post image */}
                        {/* Post content */}
                        {/* Container */}
                        <motion.div variants={inputs}>
                            <div className="container container--type-3">
                                {/* Post container */}
                                <div className="quill quill-notice" style={{ display: "block", margin: "auto" }}>
                                    <div className="ql-container ql-snow">
                                        <div className="ql-editor" dangerouslySetInnerHTML={{ __html: notice?.contents }}/>
                                    </div>
                                </div>
                                {/* End post container */}
                            </div>
                        </motion.div>
                        {/* End container */}
                    </div>
                    {/* End post */}
                </motion.div>
            </motion.section>
            <Footer />
        </React.Fragment>
    )
}