import React, {useState} from 'react';
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import useSWR from "swr";
import {Dot} from "../../utils/CommonFunction";
import {api} from "../../utils/api";
import {useParams} from "react-router-dom";
import moment from "moment";
import { motion } from "framer-motion";

export default (props) => {
    const id = useParams().id;

    const [deliveryModal, setDeliveryModal] = useState(false);
    const [deliveryCompany, setDeliveryCompany] = useState('');
    const [deliveryNumber, setDeliveryNumber] = useState('');

    const { data: orderData, mutate } = useSWR(`/order/product/detail?order_product_id=${id}`);

    const order = orderData?.order;
    const orderProduct = orderData?.order_product;

    const content = {
        animate: {
            transition: { staggerChildren: 0.1 },
        },
    };

    const inputs = {
        initial: { y: -20, opacity: 0 },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.7,
                ease: [0.6, -0.05, 0.01, 0.99],
            },
        },
    };

    const handleCancel = async (orderProductId) => {
        if (orderProductId === "") {
            window.alert("취소할 주문을 선택해주세요");
            return false;
        }

        const formdata = new FormData();
        formdata.append("order_product_id", orderProductId);

        const {
            data: { success, alert, data },
        } = await api.post("/order/cancel", formdata);

        if (success) {
            mutate()
            window.alert(alert)
        } else {
            window.alert(alert)
        }
    };

    const handleReturn = async (orderProductId) => {
        if (orderProductId === "") {
            window.alert("반품 요청할 주문을 선택해주세요");
            return false;
        }

        const formdata = new FormData();
        formdata.append("order_product_id", orderProductId);

        const {
            data: { success, alert, data },
        } = await api.post("/order/return", formdata);

        if (success) {
            mutate()
            window.alert(alert)
        } else {
            window.alert(alert)
        }
    };

    const handleExchange = async (orderProductId) => {
        if (orderProductId === "") {
            window.alert("교환 요청할 주문을 선택해주세요");
            return false;
        }

        const formdata = new FormData();
        formdata.append("order_product_id", orderProductId);

        const {
            data: { success, alert, data },
        } = await api.post("/order/exchange", formdata);

        if (success) {
            mutate()
            window.alert(alert)
        } else {
            window.alert(alert)
        }
    };

    const handleConfirm = async (orderProductId) => {
        if (orderProductId === "") {
            window.alert("구매 확정할 주문을 선택해주세요");
            return false;
        }

        const formdata = new FormData();
        formdata.append("order_product_id", orderProductId);

        const {
            data: { success, alert, data },
        } = await api.post("/order/confirm", formdata);

        if (success) {
            mutate()
            window.alert(alert)
        } else {
            window.alert(alert)
        }
    };

    return (
        <React.Fragment>
            <Header />
            <motion.section
                exit={{opacity: 0}}
                className="text-gray-700 body-font relative"
            >
                <motion.div variants={content} animate="animate" initial="initial">
                    {/* Checkout */}
                    <div className="checkout">
                        {/* Container */}
                        <div className="container container--type-3">
                            {/* Second container */}
                            <div className="container">
                                {/* Title */}
                                <motion.div variants={inputs}>
                                    <h1 className="checkout__title">주문 상세</h1>
                                </motion.div>
                                {/* End title */}
                                {/* Table */}
                                <motion.div variants={inputs}>
                                    <table className="wishlist__table">
                                        <thead>
                                        <tr>
                                            <th>주문일자</th>
                                            <th>주문번호</th>
                                            <th>주문상품</th>
                                            <th>수량</th>
                                            <th>상품금액</th>
                                            <th>상태</th>
                                            <th />
                                            <th />
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {/* Wishlist product item */}
                                        <tr>
                                            <td>
                                                <div className="wishlist-product__price">
                                                    {moment(order?.created_at).format('YYYY-MM-DD HH:mm')}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="wishlist-product__price">
                                                    {order?.code}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="wishlist__product">
                                                    <div className="wishlist-product__image">
                                                        <a href={`/product/detail/${orderProduct?.product_id}`}>
                                                            {orderProduct && (<img alt="Image" src={orderProduct?.image_url} />)}
                                                        </a>
                                                    </div>
                                                    <div className="wishlist-product__title-and-variant">
                                                        <h3 className="wishlist-product__title"><a href={`/product/detail/${orderProduct?.product_id}`}>{orderProduct?.product_name}</a></h3>
                                                        <div className="wishlist-product__variant">
                                                            {orderProduct?.option_name ? (<>옵션명 : {orderProduct?.option_name} <br/></>) : ''}
                                                            {orderProduct?.bias_color_name ? (<>바이어스 컬러 : {orderProduct?.bias_color_name} <br/></>) : ''}
                                                            {orderProduct?.use_personal === 1 && (<>텍스트 컬러 : {orderProduct?.text_color_name} / 폰트 : {orderProduct?.font} / 문구 : {orderProduct?.message}</>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="wishlist-product__price">
                                                    {Dot(orderProduct?.amount)}개
                                                </div>
                                            </td>
                                            <td>
                                                <div className="wishlist-product__price">
                                                    {Dot(orderProduct?.total_price)}원
                                                </div>
                                            </td>
                                            <td>
                                                {orderProduct?.status >= 10 ? (
                                                    <div className="wishlist-product__status wishlist-product__status--stock-out">
                                                        {getStatus(orderProduct?.status)}
                                                    </div>
                                                ) : (
                                                    <div className="wishlist-product__status wishlist-product__status--in-stock">
                                                        {getStatus(orderProduct?.status)}
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                {orderProduct?.status <= 1 && (
                                                    <a href="#" className="sixth-button danger-button" onClick={() => handleCancel(orderProduct?.id)}>주문 취소</a>
                                                )}
                                                {orderProduct?.status === 3 && (
                                                    <form action="http://info.sweettracker.co.kr/tracking/3" method="post" target="_blank">
                                                        <input type="hidden" className="form-control" id="t_key" name="t_key" value="VvL8jrfwi1ucDvbFJXN4Fw" />
                                                        <input type="hidden" className="form-control" name="t_code" id="t_code" value={orderProduct?.parcel_service} />
                                                        <input type="hidden" className="form-control" name="t_invoice" id="t_invoice" value={orderProduct?.tracking_number} />
                                                        <button type="submit" className="sixth-button primary-button" style={{ backgroundColor: "#FFF" }}>배송조회</button>
                                                    </form>
                                                )}
                                                {(orderProduct?.status >= 3 && orderProduct?.status <= 4) && (
                                                    <>
                                                        <div>
                                                            <a href="#" className="sixth-button danger-button" onClick={() => handleReturn(orderProduct?.id)} style={{ marginTop: 5 }}>반품 요청</a>
                                                        </div>
                                                        <div>
                                                            <a href="#" className="sixth-button danger-button" onClick={() => handleExchange(orderProduct?.id)} style={{ marginTop: 5 }}>교환 요청</a>
                                                        </div>
                                                    </>
                                                )}
                                                {orderProduct?.status <= 4 && (
                                                    <div>
                                                        <a href="#" className="sixth-button danger-button" onClick={() => handleConfirm(orderProduct?.id)} style={{ marginTop: 5 }}>구매 확정</a>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                        {/* End wishlist product item */}
                                        </tbody>
                                    </table>
                                </motion.div>
                                {/* End table */}
                                {/* Form */}
                                <motion.div variants={inputs}>
                                    <form style={{ marginTop: 5 }}>
                                        {/* Container */}
                                        <div className="checkout__container">
                                            {/* Billing form */}
                                            <div className="billing-form">
                                                {/* Title */}
                                                <h3 className="billing-form__heading">주문 정보</h3>
                                                {/* End title */}
                                                {/* Row */}
                                                <div className="row">
                                                    <div className="col-12 col-md-1">
                                                        <label style={{ fontSize: 12 }}>주문자명</label>
                                                    </div>
                                                    <div className="col-12 col-md-5">
                                                        <div className="form-group">
                                                            <input type="text" placeholder="이름" value={order?.name} className="form-group__input" readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-1">
                                                        <label style={{ fontSize: 12 }}>연락처</label>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <div className="form-group">
                                                            <input type="text" placeholder="연락처" value={order?.phone} className="form-group__input" readOnly={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* End row */}
                                                {/* Row */}
                                                <div className="row">
                                                    <div className="col-12 col-md-1">
                                                        <label style={{ fontSize: 12 }}>수신자명</label>
                                                    </div>
                                                    <div className="col-12 col-md-5">
                                                        <div className="form-group">
                                                            <input type="text" placeholder="수신인명" value={order?.recipient_name} className="form-group__input" readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-1">
                                                        <label style={{ fontSize: 12 }}>연락처</label>
                                                    </div>
                                                    <div className="col-12 col-md-5">
                                                        <div className="form-group">
                                                            <input type="text" placeholder="수신인 연락처" value={order?.recipient_phone} className="form-group__input" readOnly={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* End row */}
                                                {/* Row */}
                                                <div className="row">
                                                    <div className="col-12 col-md-1">
                                                        <label style={{ fontSize: 12 }}>주소</label>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        <div className="form-group">
                                                            <input type="text" placeholder="우편번호" value={order?.postcode} className="form-group__input" readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <div className="form-group">
                                                            <input type="text" placeholder="기본 주소" value={order?.address} className="form-group__input" readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <div className="form-group">
                                                            <input type="text" placeholder="상세 주소" value={order?.address_detail} className="form-group__input" readOnly={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* End row */}
                                                {/* Row */}
                                                <div className="row">
                                                    <div className="col-12 col-md-1">
                                                        <label style={{ fontSize: 12 }}>배송<br/>메시지</label>
                                                    </div>
                                                    <div className="col-12 col-md-11">
                                                        <div className="form-group">
                                                            <input type="text" placeholder="요청사항" value={order?.delivery_message} className="form-group__input" readOnly={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* End row */}
                                                {/* Row */}
                                                <div className="row">
                                                    <div className="col-12 col-md-1">
                                                        <label style={{ fontSize: 12 }}>사용쿠폰</label>
                                                    </div>
                                                    <div className="col-12 col-md-5">
                                                        <div className="form-group">
                                                            <input type="text" placeholder="사용 쿠폰" value={order?.coupon_name ? (order?.coupon_name + ' (' + order?.coupon_price + ')') : '-'} className="form-group__input" readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-1">
                                                        <label style={{ fontSize: 12 }}>사용<br/>적립금</label>
                                                    </div>
                                                    <div className="col-12 col-md-5">
                                                        <div className="form-group">
                                                            <input type="text" placeholder="사용 적립금" value={order?.point} className="form-group__input" readOnly={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* End row */}
                                                {/* Row */}
                                                {order?.payment_method === 'vbank' && (
                                                    <div className="row">
                                                        <div className="col-12 col-md-1">
                                                            <label style={{ fontSize: 12 }}>입금계좌</label>
                                                        </div>
                                                        <div className="col-12 col-md-2">
                                                            <div className="form-group">
                                                                <input type="text" value={order?.vBankName} className="form-group__input" readOnly={true} />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            <div className="form-group">
                                                                <input type="text" value={order?.vBankNumber} className="form-group__input" readOnly={true} />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            <div className="form-group">
                                                                <input type="text" value={order?.vBankHolder} className="form-group__input" readOnly={true} />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            <div className="form-group">
                                                                <input type="text" value={order?.vBankExpDate} className="form-group__input" readOnly={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* End row */}
                                                {/* Form radio */}
                                                <div className="form-radio">
                                                    <input type="radio" id="paymentMethodCard" className="form-radio__input" checked={order?.payment_method === 'card'} readOnly={true} />
                                                    <label className="form-radio__label" htmlFor="paymentMethodCard">신용카드</label>

                                                    <input type="radio" id="paymentMethodBank" className="form-radio__input" checked={order?.payment_method === 'bank'} readOnly={true} />
                                                    <label className="form-radio__label" htmlFor="paymentMethodBank">계좌이체</label>

                                                    <input type="radio" id="paymentMethodVbank" className="form-radio__input" checked={order?.payment_method === 'vbank'} readOnly={true} />
                                                    <label className="form-radio__label" htmlFor="paymentMethodVbank">가상계좌</label>
                                                </div>
                                                {/* End form radio */}
                                                {/* Form checkbox */}
                                                <div className="form-checkbox">
                                                    <input type="checkbox" id="isPresent" className="form-checkbox__input" checked={order?.is_present === 1} readOnly={true} />
                                                    <label className="form-checkbox__label" htmlFor="isPresent">선물하기 서비스 사용 여부</label>
                                                </div>
                                                {/* End form checkbox */}
                                            </div>
                                            {/* End billing form */}
                                        </div>
                                        {/* End container */}
                                    </form>
                                </motion.div>
                                {/* End form */}
                            </div>
                            {/* End second container */}
                        </div>
                        {/* End container */}
                    </div>
                </motion.div>
            </motion.section>
            {/* End checkout */}
            <Footer />
        </React.Fragment>
    )
}

function getStatus(status)
{
    if (status === 0) {
        return '입금대기'
    } else if (status === 1) {
        return '상품준비중'
    } else if (status === 2) {
        return '배송준비중'
    } else if (status === 3) {
        return '배송중'
    } else if (status === 4) {
        return '배송완료'
    } else if (status === 5) {
        return '구매확정'
    } else if (status === 10) {
        return '취소접수'
    } else if (status === 11) {
        return '취소완료'
    } else if (status === 12) {
        return '반품접수'
    } else if (status === 13) {
        return '교환접수'
    } else if (status === 14) {
        return '회수중'
    } else if (status === 15) {
        return '교환중'
    } else if (status === 16) {
        return '반품완료'
    } else if (status === 17) {
        return '교환완료'
    }
}