import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import { api } from "../../utils/api";
import { checkPhonenumber, checkEmail } from "../../utils/CommonFunction";
import { motion } from "framer-motion";

export default (props) => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [terms, setTerms] = useState("");
  const [privacyTerms, setPrivacyTerms] = useState("");

  const content = {
    animate: {
      transition: { staggerChildren: 0.1 },
    },
  };

  const inputs = {
    initial: { y: -20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  useEffect(() => {
    if (phone.length === 10 || phone.length === 11) {
      setPhone(phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
    }
    if (phone.length === 13) {
      setPhone(phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    }
  }, [phone]);

  const handleSubmit = async () => {
    if (email === "" || password === "" || name === "" || phone === "") {
      window.alert("필수 항목을 입력해주세요");
      return false;
    }

    if (terms !== "1" || privacyTerms !== "1") {
      window.alert("약관 동의시에만 가입 가능합니다");
      return false;
    }

    if (!checkPhonenumber(phone)) {
      window.alert("휴대폰 번호 형식을 재확인 해주세요");
      return false;
    }

    if (!checkEmail(email)) {
      window.alert("이메일 형식을 재확인 해주세요");
      return false;
    }

    const formdata = new FormData();
    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("name", name);
    formdata.append("phone", phone);

    const {
      data: { success, alert, data },
    } = await api.post("/join", formdata);

    if (success) {
      history.push("/login");
    } else {
      window.alert(alert);
    }
  };

  return (
      <React.Fragment>
        <Header homepage="true" />
        <motion.section
            exit={{opacity: 0}}
            className="text-gray-700 body-font relative"
        >
          <motion.div variants={content} animate="animate" initial="initial">
            {/* Login */}
            <div className="login">
              {/* Container */}
              <div className="container container--type-2">
                {/* Container */}
                <div className="container">
                  {/* Login container */}
                  <div className="login__container">
                    {/* Login d-flex */}
                    <div className="login__d-flex d-flex">
                      {/* Login left */}
                      <div className="login__full">
                        {/* Login box */}
                        <div className="login__box active js-login-in">
                          {/* Login heading */}
                          <motion.div variants={inputs}>
                            <h4 className="login__h4">Join</h4>
                            {/* End login heading */}
                            {/* Login description */}
                            <p className="login__description">계정 정보를 입력해주세요</p>
                          </motion.div>
                          {/* End login description */}
                          {/* Form */}
                          <motion.div variants={inputs}>
                            <form>
                              {/* Form group */}
                              <div className="form-group required">
                                <input type="text" required="required" name="email" placeholder="Email" className="form-group__input" value={email} onChange={(e) => setEmail(e.target.value)} />
                              </div>
                              {/* End form group */}
                              {/* Form group */}
                              <div className="form-group required">
                                <input type="password" required="required" name="password" placeholder="Password" className="form-group__input" value={password} onChange={(e) => setPassword(e.target.value)} />
                              </div>
                              {/* End form group */}
                              {/* Form group */}
                              <div className="form-group required">
                                <input type="text" required="required" name="name" placeholder="Name" className="form-group__input" value={name} onChange={(e) => setName(e.target.value)} />
                              </div>
                              {/* End form group */}
                              {/* Form group */}
                              <div className="form-group required">
                                <input type="text" required="required" name="phone" placeholder="Phone" className="form-group__input" value={phone} onChange={(e) => {
                                  const regex = /^[0-9\b -]{0,13}$/;
                                  if (regex.test(e.target.value)) {
                                    setPhone(e.target.value);
                                  }
                                }} />
                              </div>
                              {/* End form group */}
                              {/* Form checkbox */}
                              <div className="form-checkbox">
                                <input type="checkbox" id="terms" name="terms" className="form-checkbox__input" checked={terms} onClick={() => { terms === "1" ? setTerms("0") : setTerms("1") }} />
                                <label className="form-checkbox__label" htmlFor="terms"><a href="/terms/service" target="_blank">서비스 이용 약관 동의 (보기)</a></label>
                              </div>
                              {/* End form checkbox */}
                              {/* Form checkbox */}
                              <div className="form-checkbox">
                                <input type="checkbox" id="privacy_terms" name="privacy_terms" className="form-checkbox__input" checked={privacyTerms} onClick={() => { privacyTerms === "1" ? setPrivacyTerms("0") : setPrivacyTerms("1") }} />
                                <label className="form-checkbox__label" htmlFor="privacy_terms"><a href="/terms/privacy" target="_blank">개인정보 처리방침 동의 (보기)</a></label>
                              </div>
                              {/* End form checkbox */}
                              {/* Action */}
                              <div className="login__action"><input className="second-button" type="button" value="Join" onClick={handleSubmit} /></div>
                              {/* End action */}
                            </form>
                          </motion.div>
                          {/* End form */}
                        </div>
                        {/* End login box */}
                      </div>
                      {/* End login left */}
                    </div>
                    {/* End login d-flex */}
                  </div>
                  {/* End login container */}
                </div>
                {/* End container */}
              </div>
              {/* End container */}
            </div>
          </motion.div>
        </motion.section>
        {/* End login */}
        <Footer />
      </React.Fragment>
  )
}