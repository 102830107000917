import React, { useEffect, useState } from "react"
import Header from "../../sections/Header"
import Footer from "../../sections/Footer"
import useSWRInfinite from "swr/infinite";
import { api } from "../../utils/api";
import { Dot } from "../../utils/CommonFunction";
import moment from "moment";
import { motion } from "framer-motion";

export default (props) => {
    const [lastPage, setLastPage] = useState(0);

    const { data: orders, size, setSize, mutate } = useSWRInfinite(
        (pageIndex) => {
            return `/order?page=${pageIndex + 1}`;
        },
        async (url, params) => {
            const res = await api.get(url, { params });

            setLastPage(res?.data?.data?.orders?.last_page);
            return res?.data?.data?.orders?.data || res.data;
        }
    );

    const content = {
        animate: {
            transition: { staggerChildren: 0.1 },
        },
    };

    const inputs = {
        initial: { y: -20, opacity: 0 },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.7,
                ease: [0.6, -0.05, 0.01, 0.99],
            },
        },
    };

    const handleCancel = async (orderProductId) => {
        if (orderProductId === "") {
            window.alert("취소할 주문을 선택해주세요");
            return false;
        }

        const formdata = new FormData();
        formdata.append("order_product_id", orderProductId);

        const {
            data: { success, alert, data },
        } = await api.post("/order/cancel", formdata);

        if (success) {
            mutate()
            window.alert(alert)
        } else {
            window.alert(alert)
        }
    };

    return (
        <div>
            <Header />
            <motion.section
                exit={{opacity: 0}}
                className="text-gray-700 body-font relative"
            >
                <motion.div variants={content} animate="animate" initial="initial">
                    {/* Shopping cart */}
                    <div className="wishlist">
                        {/* Container */}
                        <div className="container container--type-3">
                            {/* Second container */}
                            <div className="container">
                                {/* Title */}
                                <motion.div variants={inputs}>
                                    <h1 className="wishlist__title">주문 내역</h1>
                                </motion.div>
                                {/* End title */}
                                {/*- Table responsive */}
                                <motion.div variants={inputs}>
                                    <div className="table-responsive">
                                        {/* Table */}
                                        <table className="wishlist__table">
                                            <thead>
                                            <tr>
                                                <th>주문일자</th>
                                                <th>주문번호</th>
                                                <th>주문상품</th>
                                                <th>수량</th>
                                                <th>상품금액</th>
                                                <th>상태</th>
                                                <th />
                                                <th />
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {/* Wishlist product item */}
                                            {orders && orders?.map((orderSection) =>
                                                orderSection?.map((order) => (
                                                    <tr>
                                                        <td>
                                                            <div className="wishlist-product__price">
                                                                {moment(order?.created_at).format('YYYY-MM-DD HH:mm')}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="wishlist-product__price">
                                                                {order?.code}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="wishlist__product">
                                                                <div className="wishlist-product__image">
                                                                    <a href={`/product/detail/${order?.product_id}`}>
                                                                        <img alt="Image" src={order?.image_url} />
                                                                    </a>
                                                                </div>
                                                                <div className="wishlist-product__title-and-variant">
                                                                    <h3 className="wishlist-product__title"><a href={`/product/detail/${order?.product_id}`}>{order?.product_name}</a></h3>
                                                                    <div className="wishlist-product__variant">
                                                                        {order?.option_name ? (<>옵션명 : {order?.option_name} <br/></>) : ''}
                                                                        {order?.bias_color_name ? (<>바이어스 컬러 : {order?.bias_color_name} <br/></>) : ''}
                                                                        {order?.use_personal === 1 && (<>텍스트 컬러 : {order?.text_color_name} / 폰트 : {order?.font} / 문구 : {order?.message}</>)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="wishlist-product__price">
                                                                {Dot(order?.amount)}개
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="wishlist-product__price">
                                                                {Dot(order?.total_price)}원
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {order?.status >= 10 ? (
                                                                <div className="wishlist-product__status wishlist-product__status--stock-out">
                                                                    {getStatus(order?.status)}
                                                                </div>
                                                            ) : (
                                                                <div className="wishlist-product__status wishlist-product__status--in-stock">
                                                                    {getStatus(order?.status)}
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <a href={`/order/detail/${order?.id}`} className="sixth-button" style={{ marginBottom: 5, marginRight: 5 }}>상세</a>
                                                            {order?.status <= 1 && (
                                                                <>
                                                                    <br/>
                                                                    <a href="#" className="sixth-button danger-button" onClick={() => handleCancel(order?.id)}>주문 취소</a>
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                            {/* End wishlist product item */}
                                            </tbody>
                                        </table>
                                        {/* End table */}
                                        {/* Load more */}
                                        {lastPage > size && (
                                            <div className="blog__load-more">
                                                <a className="sixth-button" onClick={() => setSize(size + 1)}>더 보기</a>
                                            </div>
                                        )}
                                        {/* End load more */}
                                    </div>
                                </motion.div>
                                {/* End table responsive */}
                            </div>
                            {/* End second container */}
                        </div>
                        {/* End container */}
                    </div>
                </motion.div>
            </motion.section>
            {/* End wishlist */}
            <Footer />
        </div>
    )
}

function getStatus(status)
{
    if (status === 0) {
        return '입금대기'
    } else if (status === 1) {
        return '상품준비중'
    } else if (status === 2) {
        return '배송준비중'
    } else if (status === 3) {
        return '배송중'
    } else if (status === 4) {
        return '배송완료'
    } else if (status === 5) {
        return '구매확정'
    } else if (status === 10) {
        return '취소접수'
    } else if (status === 11) {
        return '취소완료'
    } else if (status === 12) {
        return '반품접수'
    } else if (status === 13) {
        return '회수중'
    } else if (status === 14) {
        return '반품완료'
    }
}