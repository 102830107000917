import React, { useEffect, useState } from 'react';
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import { useParams } from 'react-router-dom';
import { EnglishChange, EnglishSpaceChange, NumberChange } from "../../utils/CommonFunction";
import Slider from "react-slick";
import useSWR from "swr";

export default (props) => {
    const params = new URLSearchParams(window.location.search);
    const type = useParams().type;

    let accessToken = params.get("access_token");

    const [image, setImage] = useState('')
    const [font, setFont] = useState('')
    const [messageMaxLength, setMessageMaxLength] = useState('')
    const [message, setMessage] = useState('')
    const [fontAlert, setFontAlert] = useState(false)
    const [x, setX] = useState(0)
    const [y, setY] = useState(0)

    const { data, mutate } = useSWR(`/admin/check?access_token=${accessToken ? accessToken : ''}`);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    useEffect(() => {
        if (data) {
            if (data?.data === 0) {
                window.location = '/404'
            }
        }
    }, [data]);

    useEffect(() => {
        if (messageMaxLength) {
            if (message?.length > messageMaxLength) {
                setMessage(message?.substr(0, messageMaxLength))
            }
        }
    }, [messageMaxLength]);

    return (
        <React.Fragment>
            <Header />
            {/* Product breadcrumb */}
            <div className="product-breadcrumb">
                {/* Container */}
                <div className="container container--type-3">
                    {/* D-flex */}
                    <div className="product-breadcrumb__d-flex d-flex align-items-center">
                    </div>
                    {/* End d-flex */}
                </div>
                {/* End container */}
            </div>
            {/* End product breadcrumb */}
            {/* Product mobile gallery */}
            <Slider className="product__mobile-gallery" {...settings}>
                <div className="mobile-product-image" key={0}>
                    <p>
                        <img src={image} />
                        <div className={'product__' + type + '_main_text ' + font + '_text'} style={{ transform: `translate3d(${x}%, ${y}%, 0)` }}>
                                <span className={type}>
                                {[...message]?.map((message_, index) =>
                                    font === 'doha' ? (<span>{[...message]?.length === 3 ? message_ : ''}</span>)
                                        : font === 'marrakech' ? (<span className={[...message]?.length === 3 ? (index === 1 ? 'large' : 'small') : ('large')}>{message_}</span>)
                                            : font === 'oslo' ? (<span className={([...message]?.length === 1 ? ('message' + [...message]?.length) : (index !== 0 ? ('message' + [...message]?.length) : ''))}>{message_}</span>)
                                                : (font === 'paris' || font === 'seoul' || font === 'miami' || font === 'berlin') ? (<span className={'message' + [...message]?.length}>{message_}</span>) : (<span>{message_}</span>)
                                )}
                                </span>
                        </div>
                    </p>
                </div>
            </Slider>
            {/* End product mobile gallery */}
            {/* Product */}
            <div className="product">
                {/* Container */}
                <div className="container container--type-3">
                    {/* Product main */}
                    <div className="product__main d-flex">
                        {/* Product thumbnails */}
                        <ul className="product__thumbnails">
                            <li key={0}>
                                <a href="#product_image" className='active'>
                                    <img src={image} />
                                </a>
                            </li>
                        </ul>
                        {/* End product thumbnails */}
                        {/* Product main image */}
                        <ul className="product__main-image js-popup-gallery">
                            <li className="active js-product-main-image" data-id="0">
                                <figure className={'iiz product__' + type + '_main_text_wrap'}>
                                    <img className="iiz__img" src={image}/>
                                    <div className={'product__' + type + '_main_text ' + font + '_text'} style={{ transform: `translate3d(${x}%, ${y}%, 0)` }}>
                                        <span className={type}>
                                        {[...message]?.map((message_, index) =>
                                            font === 'doha' ? (<span>{[...message]?.length === 3 ? message_ : ''}</span>)
                                                : font === 'marrakech' ? (<span className={[...message]?.length === 3 ? (index === 1 ? 'large' : 'small') : ('large')}>{message_}</span>)
                                                    : font === 'oslo' ? (<span className={([...message]?.length === 1 ? ('message' + [...message]?.length) : (index !== 0 ? ('message' + [...message]?.length) : ''))}>{message_}</span>)
                                                        : (font === 'paris' || font === 'seoul' || font === 'miami' || font === 'berlin') ? (<span className={'message' + [...message]?.length}>{message_}</span>) : (<span>{message_}</span>)
                                        )}
                                        </span>
                                    </div>
                                </figure>
                            </li>
                        </ul>
                        {/* End product main image */}
                        {/* Product right */}
                        <div className="product__right">
                            {/* Product title */}
                            <h1 className="product__title">개인화 문구 위치 확인</h1>
                            {/* End product title */}
                            {/* Tag */}
                            <div className="product__tag">이미지를 업로드 하신 후, 아래 숫자를 조정해 노출하고자 하는 위치를 맞춰주세요</div>
                            {/* End tag */}
                            <div className="product__options" style={{ marginTop: 0 }}>
                                <input type="file" onChange={(e) => {
                                    let reader = new FileReader();

                                    reader.onload = () => {
                                        setImage(reader.result)
                                    };

                                    reader.readAsDataURL(e.target.files[0]);
                                }}/>
                            </div>
                            {/* Product action */}
                            <div className="product__action js-product-action">
                                {/* Product quantity and add to cart */}
                                <div className="product__quantity-and-add-to-cart d-flex">
                                    <label style={{ marginTop: 20, marginRight: 10 }}>X축</label>
                                    {/* Quantity */}
                                    <div className="product__quantity">
                                        <div className="product-quantity__minus"><a onClick={() => setX(x - 1)}><i className="lnil lnil-minus" /></a></div>
                                        <input
                                            className="product-quantity__input"
                                            type="text"
                                            value={x}
                                            onChange={(e) => {
                                                setX(NumberChange(e?.target?.value))
                                            }}
                                        />
                                        <div className="product-quantity__plus"><a onClick={() => setX(x + 1)}><i className="lnil lnil-plus" /></a></div>
                                    </div>
                                    {/* End quantity */}
                                    <label style={{ marginTop: 20, marginRight: 10 }}>Y축</label>
                                    {/* Quantity */}
                                    <div className="product__quantity">
                                        <div className="product-quantity__minus"><a onClick={() => setY(y - 1)}><i className="lnil lnil-minus" /></a></div>
                                        <input
                                            className="product-quantity__input"
                                            type="text"
                                            value={y}
                                            onChange={(e) => {
                                                setY(NumberChange(e?.target?.value))
                                            }}
                                        />
                                        <div className="product-quantity__plus"><a onClick={() => setY(y + 1)}><i className="lnil lnil-plus" /></a></div>
                                    </div>
                                    {/* End quantity */}
                                </div>
                                {/* End product quantity and add to cart */}
                            </div>
                            {/* Options */}
                            <div className="product__options" style={{ marginTop: 10 }}>
                                {/* End product colors */}
                                <>
                                    <div className="product__font_select">
                                        <a onClick={() => { setFont('doha'); setMessageMaxLength(3) }}>
                                            <div className={'product__font_select_item ' + (font === 'doha' ? 'active' : '')}>
                                                <img src={require("../../assets/images/font/Icon_Doha.png")}/>
                                            </div>
                                        </a>
                                        <a onClick={() => { setFont('marrakech'); setMessageMaxLength(3) }}>
                                            <div className={'product__font_select_item ' + (font === 'marrakech' ? 'active' : '')}>
                                                <img src={require("../../assets/images/font/Icon_Marrakech.png")}/>
                                            </div>
                                        </a>
                                        <a onClick={() => { setFont('oslo'); setMessageMaxLength(3) }}>
                                            <div className={'product__font_select_item ' + (font === 'oslo' ? 'active' : '')}>
                                                <img src={require("../../assets/images/font/Icon_Sliema.png")}/>
                                            </div>
                                        </a>
                                        <a onClick={() => { setFont('cambridge'); setMessageMaxLength(1) } }>
                                            <div className={'product__font_select_item ' + (font === 'cambridge' ? 'active_last' : '')}>
                                                <img src={require("../../assets/images/font/Icon_Cambridge.png")}/>
                                            </div>
                                        </a>
                                        <a onClick={() => { setFont('paris'); setMessageMaxLength(8) }}>
                                            <div className={'product__font_select_item ' + (font === 'paris' ? 'active' : '')}>
                                                <img src={require("../../assets/images/font/Icon_Paris.png")}/>
                                            </div>
                                        </a>
                                        <a onClick={() => { setFont('seoul'); setMessageMaxLength(8) }}>
                                            <div className={'product__font_select_item ' + (font === 'seoul' ? 'active' : '')}>
                                                <img src={require("../../assets/images/font/Icon_Seoul.png")}/>
                                            </div>
                                        </a>
                                        <a onClick={() => { setFont('miami'); setMessageMaxLength(8) }}>
                                            <div className={'product__font_select_item ' + (font === 'miami' ? 'active' : '')}>
                                                <img src={require("../../assets/images/font/Icon_Miami.png")}/>
                                            </div>
                                        </a>
                                        <a onClick={() => { setFont('berlin'); setMessageMaxLength(8) }}>
                                            <div className={'product__font_select_item ' + (font === 'berlin' ? 'active' : '')}>
                                                <img src={require("../../assets/images/font/Icon_Berlin.png")}/>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="product__input">
                                        <input type="text" placeholder="문구를 입력하세요" maxLength={messageMaxLength} value={message} onChange={(e) => {
                                            if (font === '') {
                                                setFontAlert(true)
                                            } else {
                                                setFontAlert(false)
                                                if (font === 'paris' || font === 'seoul' || font === 'miami' || font === 'berlin') {
                                                    setMessage(EnglishSpaceChange(e?.target?.value))
                                                } else {
                                                    setMessage(EnglishChange(e?.target?.value))
                                                }
                                            }
                                        }} />
                                    </div>
                                    {fontAlert && (
                                        <div style={{ fontSize: 15, color: "rgb(214, 34, 32)" }}>* 폰트를 선택해주세요</div>
                                    )}
                                    {(font === 'doha' && [...message]?.length < 3) && (
                                        <div style={{ fontSize: 15, color: "rgb(214, 34, 32)" }}>* 3글자 이상 입력해주세요</div>
                                    )}
                                </>
                            </div>
                            {/* End options */}
                        </div>
                        {/* End Product right */}
                    </div>
                    {/* End Product main */}
                </div>
                {/* End Container */}
            </div>
            {/* End Product */}
            <Footer />
        </React.Fragment>
    )
}