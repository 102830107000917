import Axios from "axios";
import auth from "./auth";
export const endpoint = process.env.REACT_APP_API_URL + '/api';
export const api = Axios.create({
    baseURL: endpoint,
    headers: {
        Authorization: `Bearer ${auth.getToken()}`,
    },
});

api.interceptors.response.use(
    async (response) => {
        if (response?.config?.url.includes('/product/detail') ||
            response?.config?.url.includes('/notice/detail')) {
            if (!response?.data?.success) {
                window.location = '/404'
            }
        }

        return Promise.resolve(response)
    },
    async (error) => {
        if (error?.response?.status === 401) {
            if (auth.getToken() === null) {
                if (error?.config?.url === '/cart?coupon_id=' ||
                    error?.config?.url === '/cart/register' ||
                    error?.config?.url === '/mypage' ||
                    error?.config?.url === '/order?page=1' ||
                    error?.config?.url === '/cart?is_payment=1&ids[]=6&coupon_id=&point=') {
                    auth.clear("token");
                    window.alert('로그인이 필요한 서비스입니다.')
                    window.location = '/login'

                    return null;
                }
            } else {
                try {
                    if (auth.getRefreshToken() !== "undefined") {
                        const {
                            data: {
                                data: { access_token, refresh_token },
                            },
                        } = await api.post("/refresh/token", {
                            refresh_token: auth.getRefreshToken(),
                        });

                        if (access_token && refresh_token) {
                            auth.setToken(access_token);
                            auth.setRefreshToken(refresh_token);
                            api.defaults.headers.common["Authorization"] = access_token;
                            return api.request(error.config);
                        } else {
                            auth.clearAppStorage();
                            window.location = '/'
                        }
                    } else {
                        return null;
                    }
                } catch (err) {
                    return null;
                }
            }
        }

        return Promise.reject(error);
    }
);
