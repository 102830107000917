import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import { api } from "../../utils/api";
import {checkPhonenumber, checkEmail, NumberChange} from "../../utils/CommonFunction";
import { motion } from "framer-motion";

export default (props) => {
  const history = useHistory();

  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [isRequest, setIsRequest] = useState(false);
  const [result, setResult] = useState(false);
  const [email, setEmail] = useState(false);
  const [codeButtonDisabled, setCodeButtonDisabled] = useState(false);

  const content = {
    animate: {
      transition: { staggerChildren: 0.1 },
    },
  };

  const inputs = {
    initial: { y: -20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  useEffect(() => {
    if (phone.length === 10 || phone.length === 11) {
      setPhone(phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
    }
    if (phone.length === 13) {
      setPhone(phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    }
  }, [phone]);

  const handleCodeRequest = async () => {
    if (!checkPhonenumber(phone)) {
      window.alert("휴대폰 번호 형식을 재확인 해주세요");
      return false;
    }

    const formdata = new FormData();
    formdata.append("phone", phone);

    const {
      data: { success, alert, data },
    } = await api.post("/sms/certification", formdata);

    if (success) {
      setCodeButtonDisabled(true)
      setIsRequest(true);
    } else {
      window.alert(alert);
    }
  };

  const handleSubmit = async () => {
    if (phone === "" || code === "") {
      window.alert("필수 항목을 입력해주세요");
      return false;
    }

    if (!checkPhonenumber(phone)) {
      window.alert("휴대폰 번호 형식을 재확인 해주세요");
      return false;
    }

    if (code?.length !== 6) {
      window.alert("인증 코드를 재확인 해주세요");
      return false;
    }

    const formdata = new FormData();
    formdata.append("phone", phone);
    formdata.append("code", code);

    const {
      data: { success, alert, data },
    } = await api.post("/find/id", formdata);

    if (success) {
      setResult(true)
      setEmail(data?.email)
    } else {
      window.alert(alert);
    }
  };

  return (
      <React.Fragment>
        <Header homepage="true" />
        <motion.section
            exit={{opacity: 0}}
            className="text-gray-700 body-font relative"
        >
          <motion.div variants={content} animate="animate" initial="initial">
            {/* Login */}
            <div className="login">
              {/* Container */}
              <div className="container container--type-2">
                {/* Container */}
                <div className="container">
                  {/* Login container */}
                  <div className="login__container">
                    {/* Login d-flex */}
                    <div className="login__d-flex d-flex">
                      {/* Login left */}
                      <div className="login__full">
                        {/* Login box */}
                        <div className="login__box active js-login-in">
                          {result ? (
                              <>
                                {/* Login heading */}
                                <motion.div variants={inputs}>
                                  <h4 className="login__h4">아이디 찾기 결과</h4>
                                </motion.div>
                                {/* End login heading */}
                                {/* Form */}
                                <motion.div variants={inputs}>
                                  <div className="tab__description">
                                    {email ? email : "해당 정보로 가입된 계정이 없습니다."}
                                  </div>
                                  {/* Action */}
                                  <div className="login__action"><input className="second-button" type="button" value="로그인 화면으로 이동" onClick={() => history.push('/login')} /></div>
                                </motion.div>
                                {/* End action */}
                              </>
                          ) : (
                              <>
                                {/* Login heading */}
                                <motion.div variants={inputs}>
                                  <h4 className="login__h4">아이디 찾기</h4>
                                  {/* End login heading */}
                                  {/* Login description */}
                                  <p className="login__description">정보를 입력해주세요</p>
                                </motion.div>
                                {/* End login description */}
                                {/* Form */}
                                <form>
                                  {/* Form group */}
                                  {/*<div className="form-group required">
                        <input type="text" required="required" name="phone" placeholder="Phone" className="form-group__input" value={phone} onChange={(e) => {
                          const regex = /^[0-9\b -]{0,13}$/;
                          if (regex.test(e.target.value)) {
                            setPhone(e.target.value);
                          }
                        }} />
                      </div>*/}
                                  {/* End form group */}
                                  {/* Form group */}
                                  <motion.div variants={inputs}>
                                    <div className="form-group required">
                                      <input type="text" required="required" name="phone" placeholder="Phone" className="form-group__input_button" value={phone} onChange={(e) => {
                                        const regex = /^[0-9\b -]{0,13}$/;
                                        if (regex.test(e.target.value)) {
                                          setPhone(e.target.value);
                                        }
                                      }} />
                                      <input type="button" required="required" name="request" className="form-group__button" value="인증 요청" disabled={codeButtonDisabled} onClick={() => handleCodeRequest()} />
                                    </div>
                                    {/* End form group */}
                                    {isRequest && (
                                        <div className="form-group required">
                                          <input type="text" required="required" name="code" placeholder="Code" className="form-group__input" maxLength={6} value={code} onChange={(e) => { setCode(NumberChange(e.target.value)); }} />
                                        </div>
                                    )}
                                    {/* Action */}
                                    <div className="login__action"><input className="second-button" type="button" value="아이디 찾기" onClick={handleSubmit} /></div>
                                  </motion.div>
                                  {/* End action */}
                                </form>
                                {/* End form */}
                              </>
                          )}
                        </div>
                        {/* End login box */}
                      </div>
                      {/* End login left */}
                    </div>
                    {/* End login d-flex */}
                  </div>
                  {/* End login container */}
                </div>
                {/* End container */}
              </div>
              {/* End container */}
            </div>
          </motion.div>
        </motion.section>
        {/* End login */}
        <Footer />
      </React.Fragment>
  )
}