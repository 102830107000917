import React from "react"
import { Link } from 'react-router-dom';
import moment from 'moment';

export default (props) => {
  return (
    <div>
      {/* Our journal */}
      <div className="full-width-our-journal">
        {/* Container */}
        <div className="container container--type-3">
          {/* Title and action */}
          <div className="full-width-our-journal__title-and-action d-flex align-items-center">
            {/* Title */}
            <h3 className="full-width-our-journal__title">Story of OUIMER</h3>
            {/* End title */}
            {/* Action */}
            <div className="full-width-our-journal__action">
              <Link to="/notice/index">더 많은 글보기 <i className="lnil lnil-chevron-right" /></Link>
            </div>
            {/* End action */}
          </div>
          {/* End title and action */}
          {/* Row */}
          <div className="row">
            {props?.notices?.map((notice) => (
                <>
                  {/* Post */}
                  <div className="col-lg-6 col-xl-4">
                    <div className="full-width-post">
                      <div className="full-width-post__image">
                        <img src={notice?.thumbnail_image_url} />
                      </div>
                      <div className="full-width-post__content">
                        <div className="full-width-post__date">{moment(notice?.created_at).format('YYYY-MM-DD')}</div>
                        <h3 className="full-width-post__title"><Link to={`/notice/detail/${notice?.id}`}>{notice?.title}</Link></h3>
                        <div className="full-width-post__continue-link"><Link to={`/notice/detail/${notice?.id}`}>자세히 보기</Link></div>
                      </div>
                    </div>
                  </div>
                  {/* End post */}
                </>
            ))}
            {/* End post */}
          </div>
          {/* End row */}
        </div>
        {/* End container */}
      </div>
      {/* End our journal */}
    </div>
  )
}