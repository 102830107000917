import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import 'lazysizes';
import ProductCard from "../snippets/ProductCard";

export default (props) => {
  const products = props?.products
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1499,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
      <React.Fragment>
        {/* Product tabs */}
        <div className="product-tabs">
          {/* Container */}
          <div className="container container--type-3">
            {/* Tabs and action */}
            <div className="product-tabs__tabs-and-action d-flex align-items-center">
              {/* Tabs */}
              <ul className="product-tabs__tabs">
                <li><span>BEST ITEM</span></li>
              </ul>
              {/* End tabs */}
              {/* Action */}
              <div className="product-tabs__action">
                <Link to="/product/index">더 많은 상품 보기 <i className="lnil lnil-chevron-right" /></Link>
              </div>
              {/* End action */}
            </div>
            {/* End Tabs and action */}
            {/* Products row */}
            <div className="row products-row products-row--type-2">
              {products?.map((product, i) => {
                return (
                    <div className="col-6 col-md-4 col-xl-3 col-xxl-25" key={product.id}>
                      <ProductCard
                          key={product.id}
                          {...product}
                      />
                    </div>
                )
              })}
            </div>
            {/* End Products row */}
          </div>
          {/* End container */}
        </div>
        {/* End product tabs */}
        {/* Line 1 px */}
        <div className="container container--type-3">
          <hr />
        </div>
        {/* End line 1 px */}
      </React.Fragment>
  )
}