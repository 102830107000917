import React, { useRef, useEffect, useState } from 'react';
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import { useParams, Link } from 'react-router-dom';
import useSWR from "swr";
import {Dot, EnglishChange, English4Change, EnglishSpaceChange, NumberChange} from "../../utils/CommonFunction";
import Slider from "react-slick";
import {api} from "../../utils/api";
import { useHistory } from "react-router";
import "../../styles/product/quill.snow.css"
import { motion } from "framer-motion";

export default (props) => {
    const params = new URLSearchParams(window.location.search);
    const mediaMatch = window.matchMedia('(min-width: 991px)');
    const [matches, setMatches] = useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });

    let cartId = params.get("cart_id");

    const id = useParams().id;

    const history = useHistory();

    const sliderRef = useRef(null)

    const [productName, setProductName] = useState('')
    const [productPrice, setProductPrice] = useState('')
    const [productShortContents, setProductShortContents] = useState('')
    const [productSetRecommend, setProductSetRecommend] = useState('')

    const [fromImages, setFromImages] = useState(0)
    const [toImages, setToImages] = useState('')
    const [activeImage, setActiveImage] = useState({src: '', index: ''})
    const [activeDesktopTab, setActiveDesktopTab] = useState(false)
    const [activeMobileTab, setActiveMobileTab] = useState(false)

    const [amount, setAmount] = useState(1)
    const [optionId, setOptionId] = useState('')
    const [biasColorId, setBiasColorId] = useState('')
    const [biasColorName, setBiasColorName] = useState('')

    const [usePersonal, setUsePersonal] = useState(false)
    const [textColorId, setTextColorId] = useState('')
    const [textColorName, setTextColorName] = useState('')
    const [textColorCode, setTextColorCode] = useState('')
    const [font, setFont] = useState('')
    const [messageMaxLength, setMessageMaxLength] = useState('')
    const [message, setMessage] = useState('')
    const [fontAlert, setFontAlert] = useState(false)

    const [biasColors, setBiasColors] = useState([])
    const [textColors, setTextColors] = useState([])

    const { data, mutate } = useSWR(`/product/detail?product_id=${id}&bias_color_id=${biasColorId}`);
    const product = data?.product;

    const { data: cartData } = useSWR(`/cart?id=${cartId}`);

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const content = {
        animate: {
            transition: { staggerChildren: 0.1 },
        },
    };

    const inputs = {
        initial: { y: -20, opacity: 0 },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.7,
                ease: [0.6, -0.05, 0.01, 0.99],
            },
        },
    };

    function openDesktopTab(index) {
        setActiveDesktopTab(index);
    }

    function openMobileTab(index) {
        setActiveMobileTab(index);
    }

    function changeImage(src, index) {
        setActiveImage({'src': src, 'index': index})
        sliderRef.current.slickGoTo(0)
    }

    function prevImages() {
        if(fromImages > 5) {
            setFromImages(fromImages-6);
            setToImages(toImages-6);
        }
    }

    function nextImages() {
        if(toImages < product.images.length) {
            setFromImages(fromImages+6);
            setToImages(toImages+6);
        }
    }

    useEffect(() => {
        if (localStorage.getItem("product_id")) {
            setOptionId(localStorage.getItem("option_id"))
            setBiasColorId(localStorage.getItem("bias_color_id"))
            setUsePersonal(localStorage.getItem("use_personal"))
            setTextColorId(localStorage.getItem("text_color_id"))
            setFont(localStorage.getItem("font"))
            setMessage(localStorage.getItem("message"))
            setAmount(localStorage.getItem("amount"))
            setBiasColorName(localStorage.getItem("biasColorName"))
            setTextColorName(localStorage.getItem("textColorName"))
            setTextColorCode(localStorage.getItem("textColorCode"))
            setMessageMaxLength(localStorage.getItem("messageMaxLength"))

            setTimeout(function() {
                localStorage.removeItem("product_id");
                localStorage.removeItem("option_id");
                localStorage.removeItem("bias_color_id");
                localStorage.removeItem("use_personal");
                localStorage.removeItem("text_color_id");
                localStorage.removeItem("font");
                localStorage.removeItem("message");
                localStorage.removeItem("amount");
                localStorage.removeItem("biasColorName");
                localStorage.removeItem("textColorName");
                localStorage.removeItem("textColorCode");
                localStorage.removeItem("messageMaxLength");
            }, 3000);
        }
    }, [localStorage.getItem("product_id")]);

    useEffect(() => {
        if (data) {
            if (biasColors?.length === 0) {
                setBiasColors(data?.bias_colors)
            }
            if (textColors?.length === 0) {
                setTextColors(data?.text_colors)
            }
        }
    }, [data]);

    useEffect(() => {
        if (product) {
            if (productName === '') {
                setProductName(product?.name)
            }
            if (productPrice === '') {
                setProductPrice(product?.price)
            }
            if (productShortContents === '') {
                setProductShortContents(product?.short_contents)
            }
            if (productSetRecommend === '') {
                setProductSetRecommend(product?.product_set_recommend)
            }

            if (biasColorId === '') {
                if (data?.bias_colors?.length > 0) {
                    setBiasColorId(data?.bias_colors[0]?.id)
                    setBiasColorName(data?.bias_colors[0]?.name)
                }
            }

            setToImages(product?.thumbnail_images?.length)

            if (usePersonal) {
                changeImage(product?.personal_image?.image_url, 0);
            } else {
                changeImage(product?.thumbnail_images[0]?.image_url, 0);
            }
        }
    }, [product]);

    useEffect(() => {
        if (cartData && cartId) {
            setUsePersonal(cartData?.carts?.use_personal === 1)
            setAmount(cartData?.carts?.amount)
            setOptionId(cartData?.carts?.option_id ? cartData?.carts?.option_id : '')
            setBiasColorId(cartData?.carts?.bias_color_id ? cartData?.carts?.bias_color_id : '')
            setBiasColorName(cartData?.carts?.bias_color_name ? cartData?.carts?.bias_color_name : '')
            setTextColorId(cartData?.carts?.text_color_id ? cartData?.carts?.text_color_id : '')
            setTextColorName(cartData?.carts?.text_color_name ? cartData?.carts?.text_color_name : '')
            setTextColorCode(cartData?.carts?.text_color_code ? cartData?.carts?.text_color_code : '')
            setFont(cartData?.carts?.font ? cartData?.carts?.font : '')
            setMessage(cartData?.carts?.message ? cartData?.carts?.message : '')
            setMessageMaxLength(cartData?.carts?.font_length)
        }
    }, [cartData]);

    useEffect(() => {
        if (messageMaxLength) {
            if (message?.length > messageMaxLength) {
                setMessage(message?.substr(0, messageMaxLength))
            }
        }
    }, [messageMaxLength]);

    const handleSubmit = async (isCart = false) => {
        if (id === "") {
            window.alert("필수 항목을 입력해주세요");
            return false;
        }

        localStorage.setItem("product_id", id);
        localStorage.setItem("option_id", optionId);
        localStorage.setItem("bias_color_id", biasColorId);
        localStorage.setItem("use_personal", usePersonal);
        localStorage.setItem("text_color_id", textColorId);
        localStorage.setItem("font", font);
        localStorage.setItem("message", message);
        localStorage.setItem("amount", amount);
        localStorage.setItem("biasColorName", biasColorName);
        localStorage.setItem("textColorName", textColorName);
        localStorage.setItem("textColorCode", textColorCode);
        localStorage.setItem("messageMaxLength", messageMaxLength);

        const formdata = new FormData();
        formdata.append("product_id", id);
        formdata.append("option_id", optionId);
        formdata.append("bias_color_id", biasColorId);
        formdata.append("use_personal", usePersonal ? 1 : 0);
        formdata.append("text_color_id", textColorId);
        formdata.append("font", font);
        formdata.append("message", message);
        formdata.append("amount", amount?.toString());
        formdata.append("is_order", isCart ? 0 : 1);

        const {
            data: { success, alert, data },
        } = await api.post("/cart/register", formdata);

        if (success) {
            if (isCart) {
                window.alert('장바구니에 담겼습니다')
            } else {
                history.push('/checkout?ids=' + data?.id)
            }
        } else {
            window.alert(alert);
        }
    };

    const handleModify = async () => {
        if (cartId === "") {
            window.alert("필수 항목을 입력해주세요");
            return false;
        }

        const formdata = new FormData();
        formdata.append("cart_id", cartId);
        formdata.append("option_id", optionId);
        formdata.append("bias_color_id", biasColorId);
        formdata.append("use_personal", usePersonal ? 1 : 0);
        formdata.append("text_color_id", textColorId);
        formdata.append("font", font);
        formdata.append("message", message);
        formdata.append("amount", amount?.toString());

        const {
            data: { success, alert, data },
        } = await api.post("/cart/modify", formdata);

        if (success) {
            history.push('/cart')
        } else {
            window.alert(alert);
        }
    };

    return (
        <React.Fragment>
            <Header />
            <motion.section
                exit={{opacity: 0}}
                className="text-gray-700 body-font relative"
            >
                <motion.div variants={content} animate="animate" initial="initial">
                {/* Product breadcrumb */}
            <div className="product-breadcrumb">
                {/* Container */}
                <div className="container container--type-3">
                    {/* D-flex */}
                    <div className="product-breadcrumb__d-flex d-flex align-items-center">
                        {/* Breadcrumb */}
                        <ol className="breadcrumb text-uppercase">
                            <li className="breadcrumb__item"><a href="/product/index">상품 목록</a></li>
                            <li className="breadcrumb__item active" aria-current="page">{productName}</li>
                        </ol>
                        {/* End breadcrumb */}
                    </div>
                    {/* End d-flex */}
                </div>
                {/* End container */}
            </div>
            {/* End product breadcrumb */}
            {/* Product mobile gallery */}
            <Slider className="product__mobile-gallery" {...settings} ref={sliderRef}>
                {usePersonal ? (
                    <div className="mobile-product-image" key={0}>
                        <p>
                            <img src={product?.personal_image?.image_url} />
                            <div className={'product__' + product?.type + '_main_text ' + font + '_text'} style={{ color: `${textColorCode}`, transform: `translate3d(${matches ? product?.personal_image?.pc_x : product?.personal_image?.mobile_x}%, ${matches ? product?.personal_image?.pc_y : product?.personal_image?.mobile_y}%, 0)` }}>
                                <span className={product?.type}>
                                {[...message]?.map((message_, index) =>
                                    font === 'doha' ? (<span>{[...message]?.length === 3 ? message_ : ''}</span>)
                                        : font === 'marrakech' ? (<span className={[...message]?.length === 3 ? (index === 1 ? 'large' : 'small') : ('large')}>{message_}</span>)
                                            : font === 'oslo' ? (<span className={([...message]?.length === 1 ? ('message' + [...message]?.length) : (index !== 0 ? ('message' + [...message]?.length) : ''))}>{message_}</span>)
                                                : (font === 'paris' || font === 'seoul' || font === 'miami' || font === 'berlin') ? (<span className={'message' + [...message]?.length}>{message_}</span>) : (<span>{message_}</span>)
                                )}
                                </span>
                            </div>
                        </p>
                    </div>
                ) : product?.thumbnail_images?.map((thumbnailImage, index) => {
                    return (
                        <div className="mobile-product-image" key={index}>
                            <p>
                                <img src={thumbnailImage?.image_url} />
                            </p>
                        </div>
                    );
                })}
            </Slider>
            {/* End product mobile gallery */}
            {/* Product */}
            <div className="product">
                {/* Container */}
                <div className="container container--type-3">
                    {/* Product main */}
                    <div className="product__main d-flex">
                        {/* Product thumbnails */}
                        <ul className="product__thumbnails">
                            {usePersonal ? (
                                <li key={0}>
                                    <a href="#product_image" className='active'>
                                        <img src={product?.personal_image?.image_url} />
                                    </a>
                                </li>
                            ) : (
                                product?.thumbnail_images?.map((thumbnailImage, index) => {
                                    return (
                                        <li key={index} className={index >= fromImages && index < toImages ? '' : 'd-none' }>
                                            <a href="#product_image" className={activeImage.index === index ? 'active' : '' } onClick={() => changeImage(thumbnailImage?.image_url, index)}>
                                                <img src={thumbnailImage?.image_url} />
                                            </a>
                                        </li>
                                    );
                                })
                            )}
                            <li className={product?.thumbnail_images?.length > 6 ? 'product-thumbnails__next' : 'product-thumbnails__next d-none' }>
                                <p className="js-product-thumbnails-prev" onClick={() => prevImages()} />
                                <p className="js-product-thumbnails-next" onClick={() => nextImages()} />
                            </li>
                        </ul>
                        {/* End product thumbnails */}
                        {/* Product main image */}
                        <ul className="product__main-image js-popup-gallery">
                            <li className="active js-product-main-image" data-id="0">
                                <figure className={'iiz product__' + product?.type + '_main_text_wrap'}>
                                    <img className="iiz__img" src={activeImage?.src}/>
                                    <div className={'product__' + product?.type + '_main_text ' + font + '_text'} style={{ color: `${textColorCode}`, transform: `translate3d(${matches ? product?.personal_image?.pc_x : product?.personal_image?.mobile_x}%, ${matches ? product?.personal_image?.pc_y : product?.personal_image?.mobile_y}%, 0)` }}>
                                        <span className={product?.type}>
                                        {[...message]?.map((message_, index) =>
                                            font === 'doha' ? (<span>{[...message]?.length === 3 ? message_ : ''}</span>)
                                                : font === 'marrakech' ? (<span className={[...message]?.length === 3 ? (index === 1 ? 'large' : 'small') : ('large')}>{message_}</span>)
                                                    : font === 'oslo' ? (<span className={([...message]?.length === 1 ? ('message' + [...message]?.length) : (index !== 0 ? ('message' + [...message]?.length) : ''))}>{message_}</span>)
                                                        : (font === 'paris' || font === 'seoul' || font === 'miami' || font === 'berlin') ? (<span className={'message' + [...message]?.length}>{message_}</span>) : (<span>{message_}</span>)
                                        )}
                                        </span>
                                    </div>
                                </figure>
                            </li>
                        </ul>
                        {/* End product main image */}
                        {/* Product right */}
                        <div className="product__right">
                            <motion.div variants={inputs}>
                            {/* Tag */}
                            <div className="product__tag">OUIMER</div>
                            {/* End tag */}
                            {/* Product title */}
                            <h1 className="product__title">{productName}</h1>
                            {/* End product title */}
                            {/* Product price */}
                            <div className="product__price">
                                <React.Fragment>
                                    <span>
                                        {Dot(productPrice)}원
                                        {/*{usePersonal ? Dot(product?.price + data?.bias_color_price) : Dot(product?.price)}원*/}
                                    </span>
                                </React.Fragment>
                            </div>
                            {/* End product price */}
                            {/* Status */}
                            <div className="product__status">
                                <i className="lnir lnir-package" />
                                <span>판매 상태:</span>
                                {product ? (<span className={'status__value ' + (product?.remain_amount > 0 ? 'status__value--in-stock' : 'status__value--out-of-stock')}>{product?.remain_amount > 0 ? ('주문가능') : ('품절')}</span>) : ('-')}
                            </div>
                            </motion.div>
                            {/* End product status */}
                            {/* Options */}
                            <motion.div variants={inputs}>
                            <div className="product__options">
                                {/* Product colors */}
                                {biasColors?.length > 0 && (
                                    <div className="product__colors d-flex align-items-center">
                                        <strong className="product__section-label">color</strong>
                                        {/* Available colors */}
                                        <ul className="product__available-colors">
                                            {biasColors?.map((biasColor) => (
                                                <li className={(biasColorId?.toString() === biasColor?.id?.toString() ? 'active' : '')}>
                                                    <a style={{ backgroundImage: "linear-gradient(to top, " + biasColor?.code + " 50%, " + biasColor?.code2 + " 50%)" }}
                                                       className="open-tooltip"
                                                       onClick={() => {
                                                           setBiasColorId(biasColor?.id)
                                                           setBiasColorName(biasColor?.name)
                                                       }}>
                                                        <span className="custom-tooltip">{biasColor?.name}</span>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                        {/* End available colors */}
                                        {/* Current color */}
                                        <div className="product__current-color">바이어스 컬러: <span>{biasColorName ? biasColorName : '-'}</span></div>
                                        {/* End current color */}
                                    </div>
                                )}
                                {/* Product sizes */}
                                {product?.product_options?.length > 0 && (
                                    <div className="product__sizes">
                                        <select
                                            id="prodOptions"
                                            name={optionId}
                                            onChange={(e) => {
                                                setOptionId(e.target.value)
                                            }}
                                        >
                                            {product?.product_options?.map((option, i) => {
                                                return (
                                                    <option
                                                        value={option?.id}
                                                        key={option?.id}
                                                    >{`${option?.name}`} {option?.price > 0 ? ('(+' + option?.price + '원)') : ''}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </div>
                                )}
                                {/* End product sizes */}
                                {/* End product colors */}
                                <div className="product__input">
                                    <button onClick={() => {
                                        if (usePersonal) {
                                            changeImage(product?.thumbnail_images[0]?.image_url, 0);
                                            setTextColorId('');
                                            setTextColorName('');
                                            setTextColorCode('');
                                            setMessage('');
                                            setProductPrice(product?.price)
                                        } else {
                                            changeImage(product?.personal_image?.image_url, 0);
                                            setTextColorId(textColors[0]?.id);
                                            setTextColorName(textColors[0]?.name);
                                            setTextColorCode(textColors[0]?.code);
                                            setProductPrice(product?.price + data?.bias_color_price)
                                        }
                                        setUsePersonal(!usePersonal);
                                    }}>{usePersonal ? '개인화 옵션 선택 해제' : "개인화 옵션 선택"} {!usePersonal && (<span style={{ color: "gray" }}>(+{Dot(data?.bias_color_price)}원)</span>)}</button>
                                </div>
                                {usePersonal && (
                                    <>
                                        <strong className="product__section-label">font style</strong>
                                        <div className="product__font_select">
                                            <a onClick={() => { setFont('oslo'); setMessageMaxLength(3); setFontAlert(false) }}>
                                                <div className={'product__font_select_item ' + (font === 'oslo' ? 'active' : '')}>
                                                    <img src={require("../../assets/images/font/Icon_Sliema.png")}/>
                                                </div>
                                            </a>
                                            <a onClick={() => { setFont('marrakech'); setMessageMaxLength(3); setFontAlert(false); setMessage(message.toUpperCase()) }}>
                                                <div className={'product__font_select_item ' + (font === 'marrakech' ? 'active' : '')}>
                                                    <img src={require("../../assets/images/font/Icon_Marrakech.png")}/>
                                                </div>
                                            </a>
                                            <a onClick={() => { setFont('doha'); setMessageMaxLength(3); setFontAlert(false) }}>
                                                <div className={'product__font_select_item ' + (font === 'doha' ? 'active' : '')}>
                                                    <img src={require("../../assets/images/font/Icon_Doha.png")}/>
                                                </div>
                                            </a>
                                            <a onClick={() => { setFont('cambridge'); setMessageMaxLength(1); setFontAlert(false) } }>
                                                <div className={'product__font_select_item ' + (font === 'cambridge' ? 'active_last' : '')}>
                                                    <img src={require("../../assets/images/font/Icon_Cambridge.png")}/>
                                                </div>
                                            </a>
                                            <a onClick={() => { setFont('paris'); setMessageMaxLength(8); setFontAlert(false) }}>
                                                <div className={'product__font_select_item ' + (font === 'paris' ? 'active' : '')}>
                                                    <img src={require("../../assets/images/font/Icon_Paris.png")}/>
                                                </div>
                                            </a>
                                            <a onClick={() => { setFont('seoul'); setMessageMaxLength(8); setFontAlert(false) }}>
                                                <div className={'product__font_select_item ' + (font === 'seoul' ? 'active' : '')}>
                                                    <img src={require("../../assets/images/font/Icon_Seoul.png")}/>
                                                </div>
                                            </a>
                                            <a onClick={() => { setFont('miami'); setMessageMaxLength(8); setFontAlert(false) }}>
                                                <div className={'product__font_select_item ' + (font === 'miami' ? 'active' : '')}>
                                                    <img src={require("../../assets/images/font/Icon_Miami.png")}/>
                                                </div>
                                            </a>
                                            <a onClick={() => { setFont('berlin'); setMessageMaxLength(8); setFontAlert(false) }}>
                                                <div className={'product__font_select_item ' + (font === 'berlin' ? 'active' : '')}>
                                                    <img src={require("../../assets/images/font/Icon_Berlin.png")}/>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="clear" style={{ clear: "both" }}/>
                                        <div className="product__input">
                                            <input type="text" style={{ backgroundColor: '#f6f6f6' }} placeholder="문구를 입력하세요" maxLength={messageMaxLength} value={message} onChange={(e) => {
                                                if (font === '') {
                                                    setFontAlert(true)
                                                } else {
                                                    setFontAlert(false)
                                                    if (font === 'paris' || font === 'seoul' || font === 'miami' || font === 'berlin') {
                                                        setMessage(EnglishSpaceChange(e?.target?.value))
                                                    } else {
                                                        if (font === 'marrakech') {
                                                            setMessage(EnglishChange(e?.target?.value.toUpperCase()))
                                                        } else if (font === 'cambridge') {
                                                            setMessage(English4Change(e?.target?.value))
                                                        } else {
                                                            setMessage(EnglishChange(e?.target?.value))
                                                        }
                                                    }
                                                }
                                            }} />
                                        </div>
                                        {(messageMaxLength && font !== 'doha') && (
                                            <div style={{ fontSize: 15 }}>* 글자수 최대 {(font === 'doha' || font === 'cambridge') ? messageMaxLength : ('1 ~ ' + messageMaxLength)}자 가능</div>
                                        )}
                                        {fontAlert && (
                                            <div style={{ fontSize: 15, color: "rgb(214, 34, 32)" }}>* 폰트를 선택해주세요</div>
                                        )}
                                        {(font === 'doha' && [...message]?.length < 3) && (
                                            <div style={{ fontSize: 15, color: "rgb(214, 34, 32)" }}>* 3글자 이상 입력해주세요</div>
                                        )}
                                        {/* Product colors */}
                                        <div className="product__colors d-flex align-items-center">
                                            <strong className="product__section-label">font color</strong>
                                            {/* Available colors */}
                                            <ul className="product__available-colors">
                                                {textColors?.map((textColor) => (
                                                    <li className={(textColorId?.toString() === textColor?.id?.toString() ? 'active' : '')}>
                                                        <a style={{ background: textColor?.code }}
                                                           className="open-tooltip"
                                                           onClick={() => {
                                                               setTextColorId(textColor?.id)
                                                               setTextColorName(textColor?.name)
                                                               setTextColorCode(textColor?.code)
                                                           }}>
                                                            <span className="custom-tooltip">{textColor?.name}</span>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                            {/* End available colors */}
                                            {/* Current color */}
                                            <div className="product__current-color">텍스트 컬러: <span>{textColorName ? textColorName : '-'}</span></div>
                                            {/* End current color */}
                                        </div>
                                        {/* End product colors */}
                                    </>
                                )}
                            </div>
                            </motion.div>
                            {/* End options */}
                            {/* Product action */}
                            <motion.div variants={inputs}>
                            <div className="product__action js-product-action">
                                {/* Product quantity and add to cart */}
                                <div className="product__quantity-and-add-to-cart d-flex">
                                    {/* Quantity */}
                                    <div className="product__quantity">
                                        <div className="product-quantity__minus"><a onClick={() => {amount > 1 && (setAmount(amount - 1)) }}><i className="lnil lnil-minus" /></a></div>
                                        <input
                                            className="product-quantity__input"
                                            type="text"
                                            min={1}
                                            max={product?.remain_amount}
                                            value={amount}
                                            onChange={(e) => {
                                                if (e?.target?.value <= 0) {
                                                    setAmount(1)
                                                } else if (e?.target?.value > product?.remain_amount) {
                                                    setAmount(NumberChange(product?.remain_amount))
                                                } else {
                                                    setAmount(NumberChange(e?.target?.value))
                                                }
                                            }}
                                        />
                                        <div className="product-quantity__plus"><a onClick={() => { (amount + 1) > product?.remain_amount ? setAmount(product?.remain_amount) : setAmount(amount + 1) }}><i className="lnil lnil-plus" /></a></div>
                                    </div>
                                    {/* End quantity */}
                                    {/* Add to cart */}
                                    <div className="product__add-to-cart">
                                        {cartId ? (<button className={'eighth-button ' + (product?.remain_amount <= 0 ? 'disabled' : 'color')} onClick={() => handleModify()} disabled={product?.remain_amount <= 0}>장바구니 수정</button>)
                                            : (<button className={'eighth-button ' + (product?.remain_amount <= 0 ? 'disabled' : 'color')} onClick={() => handleSubmit(true)} disabled={product?.remain_amount <= 0}>장바구니 담기</button>)}
                                    </div>
                                    {/* End add to cart */}
                                </div>
                                {/* End product quantity and add to cart */}
                                {/* Buy now */}
                                {!cartId && (
                                    <div className="product__buy-now">
                                        <button className={'second-button ' + (product?.remain_amount <= 0 ? 'disabled' : 'color')} onClick={() => handleSubmit()} disabled={product?.remain_amount <= 0}>구매하기</button>
                                    </div>
                                )}
                                {/* End buy now */}
                            </div>
                            </motion.div>
                            {/* End product action */}
                            {/* Product information */}
                            <motion.div variants={inputs}>
                            <div className="product__information">
                                <p>{productShortContents}</p>
                            </div>
                            {/* End product information */}

                            {/* Mobile tabs */}
                            <div className="product__mobile-tabs">
                                {/* Accordion */}
                                <div className={activeMobileTab === 1 ? 'active accordion' : 'accordion'}>
                                    {/* Title */}
                                    <div className="accordion__title" onClick={() => openMobileTab(1)}>
                                        상세 설명
                                    </div>
                                    {/* End title */}
                                    {/* Content */}
                                    <div className="quill">
                                        <div className="ql-container ql-snow">
                                            <div className="accordion__content ql-editor" dangerouslySetInnerHTML={{ __html: product?.contents }}/>
                                        </div>
                                    </div>
                                    {/* End content */}
                                </div>
                                {/* End accordion */}
                                {/* Accordion */}
                                <div className={activeMobileTab === 2 ? 'active accordion' : 'accordion'}>
                                    {/* Title */}
                                    <div className="accordion__title" onClick={() => openMobileTab(2)}>
                                        Detail of OUIMER
                                    </div>
                                    {/* End title */}
                                    {/* Content */}
                                    <div className="quill">
                                        <div className="ql-container ql-snow">
                                            <div className="accordion__content js-accordion-content ql-editor" dangerouslySetInnerHTML={{ __html: data?.product_detail }}/>
                                        </div>
                                    </div>
                                    {/* End content */}
                                </div>
                                {/* End accordion */}
                                {/* Accordion */}
                                <div className={activeMobileTab === 3 ? 'active accordion' : 'accordion'}>
                                    {/* Title */}
                                    <div className="accordion__title" onClick={() => openMobileTab(3)}>
                                        교환 및 환불
                                    </div>
                                    {/* End title */}
                                    {/* Content */}
                                    <div className="quill">
                                        <div className="ql-container ql-snow">
                                            <div className="accordion__content js-accordion-content ql-editor" dangerouslySetInnerHTML={{ __html: data?.product_refund }}/>
                                        </div>
                                    </div>
                                    {/* End content */}
                                </div>
                                {/* End accordion */}
                                {/* Accordion */}
                                <div className={activeMobileTab === 4 ? 'active accordion' : 'accordion'}>
                                    {/* Title */}
                                    <div className="accordion__title" onClick={() => openMobileTab(4)}>
                                        배송 정책
                                    </div>
                                    {/* End title */}
                                    {/* Content */}
                                    <div className="quill">
                                        <div className="ql-container ql-snow">
                                            <div className="accordion__content js-accordion-content ql-editor" dangerouslySetInnerHTML={{ __html: data?.product_delivery }}/>
                                        </div>
                                    </div>
                                    {/* End content */}
                                </div>
                                {/* End accordion */}
                            </div>
                            </motion.div>
                            {/* End mobile tabs */}
                            {/* Product tabs */}
                            <motion.div variants={inputs}>
                            <ul className="product__tabs d-flex">
                                <li><a className="js-open-tab" onClick={() => openDesktopTab(1)}>상세 설명</a></li>
                                <li><a className="js-open-tab" onClick={() => openDesktopTab(2)}>Detail of OUIMER</a></li>
                                <li><a className="js-open-tab" onClick={() => openDesktopTab(3)}>교환 및 환불</a></li>
                                <li><a className="js-open-tab" onClick={() => openDesktopTab(4)}>배송 정책</a></li>
                            </ul>
                            </motion.div>
                            {/* End product tabs */}
                            <motion.div variants={inputs}>
                            {productSetRecommend && (
                                <div className="product__set_recommend">
                                    <b className="product__set_label">세트 제안</b>
                                    <div className="set__products">
                                        <div className="set__product">
                                            <div className="checkout-product__image">
                                                <a href={`/product/detail/${productSetRecommend?.id}`}>
                                                    <img alt="Image" src={productSetRecommend?.image_url} />
                                                </a>
                                            </div>
                                            <div className="checkout-product__title-and-variant">
                                                <h3 className="checkout-product__title"><a href={`/product/detail/${productSetRecommend?.id}`}>{productSetRecommend?.name}</a></h3>
                                                <div className="checkout-product__variant">{productSetRecommend?.short_contents}</div>
                                            </div>
                                            <div className="checkout-product__price">{Dot(productSetRecommend?.price) + '원'}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            </motion.div>
                            {/* Tab description */}
                            <div className={activeDesktopTab === 1 ? 'active tab' : 'tab'}>
                                <div className="tab__overlay" onClick={() => openDesktopTab(0)} />
                                {/* Tab content */}
                                <div className="tab__content">
                                    {/* Heading */}
                                    <div className="tab__heading d-flex align-items-center">
                                        {/* H3 */}
                                        <h3 className="tab__h3">상세 설명</h3>
                                        {/* End h3 */}
                                        {/* Close */}
                                        <div className="tab__close"><a onClick={() => openDesktopTab(0)}><i className="lnil lnil-close" /></a></div>
                                        {/* End close */}
                                    </div>
                                    {/* End heading */}
                                    {/* Description */}
                                    <div className="quill">
                                        <div className="ql-container ql-snow">
                                            <div className="tab__description ql-editor" dangerouslySetInnerHTML={{ __html: product?.contents }}/>
                                        </div>
                                    </div>
                                    {/* End description */}
                                </div>
                                {/* End tab content */}
                            </div>
                            {/* End tab description */}
                            {/* Tab description */}
                            <div className={activeDesktopTab === 2 ? 'active tab' : 'tab'}>
                                <div className="tab__overlay" onClick={() => openDesktopTab(0)} />
                                {/* Tab content */}
                                <div className="tab__content">
                                    {/* Heading */}
                                    <div className="tab__heading d-flex align-items-center">
                                        {/* H3 */}
                                        <h3 className="tab__h3">Detail of OUIMER</h3>
                                        {/* End h3 */}
                                        {/* Close */}
                                        <div className="tab__close"><a onClick={() => openDesktopTab(0)}><i className="lnil lnil-close" /></a></div>
                                        {/* End close */}
                                    </div>
                                    {/* End heading */}
                                    {/* Description */}
                                    <div className="quill">
                                        <div className="ql-container ql-snow">
                                            <div className="tab__description ql-editor" dangerouslySetInnerHTML={{ __html: data?.product_detail }}/>
                                        </div>
                                    </div>
                                    {/* End description */}
                                </div>
                                {/* End tab content */}
                            </div>
                            {/* End tab description */}
                            {/* Tab description */}
                            <div className={activeDesktopTab === 3 ? 'active tab' : 'tab'}>
                                <div className="tab__overlay" onClick={() => openDesktopTab(0)} />
                                {/* Tab content */}
                                <div className="tab__content">
                                    {/* Heading */}
                                    <div className="tab__heading d-flex align-items-center">
                                        {/* H3 */}
                                        <h3 className="tab__h3">교환 및 환불</h3>
                                        {/* End h3 */}
                                        {/* Close */}
                                        <div className="tab__close"><a onClick={() => openDesktopTab(0)}><i className="lnil lnil-close" /></a></div>
                                        {/* End close */}
                                    </div>
                                    {/* End heading */}
                                    {/* Description */}
                                    <div className="quill">
                                        <div className="ql-container ql-snow">
                                            <div className="tab__description ql-editor" dangerouslySetInnerHTML={{ __html: data?.product_refund }}/>
                                        </div>
                                    </div>
                                    {/* End description */}
                                </div>
                                {/* End tab content */}
                            </div>
                            {/* End tab description */}
                            {/* Tab description */}
                            <div className={activeDesktopTab === 4 ? 'active tab' : 'tab'}>
                                <div className="tab__overlay" onClick={() => openDesktopTab(0)} />
                                {/* Tab content */}
                                <div className="tab__content">
                                    {/* Heading */}
                                    <div className="tab__heading d-flex align-items-center">
                                        {/* H3 */}
                                        <h3 className="tab__h3">배송 정책</h3>
                                        {/* End h3 */}
                                        {/* Close */}
                                        <div className="tab__close"><a onClick={() => openDesktopTab(0)}><i className="lnil lnil-close" /></a></div>
                                        {/* End close */}
                                    </div>
                                    {/* End heading */}
                                    {/* Description */}
                                    <div className="quill">
                                        <div className="ql-container ql-snow">
                                            <div className="tab__description ql-editor" dangerouslySetInnerHTML={{ __html: data?.product_delivery }}/>
                                        </div>
                                    </div>
                                    {/* End description */}
                                </div>
                                {/* End tab content */}
                            </div>
                            {/* End tab description */}
                        </div>
                        {/* End Product right */}
                    </div>
                    {/* End Product main */}
                </div>
                {/* End Container */}
            </div>
                </motion.div>
            </motion.section>
            {/* End Product */}
            <Footer />
        </React.Fragment>
    )
}