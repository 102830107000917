import React  from 'react';
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import HomeProducts from "../../sections/HomeProducts"
import useSWR from "swr";
import Notice from "../../sections/Notice";
import Slider from "../../sections/Slider";
import Instagram from "../../sections/Instagram";
import { motion } from "framer-motion";

export default (props) => {
    const { data, mutate } = useSWR(`/main`);

    const content = {
        animate: {
            transition: { staggerChildren: 0.1 },
        },
    };

    const inputs = {
        initial: { y: -20, opacity: 0 },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.7,
                ease: [0.6, -0.05, 0.01, 0.99],
            },
        },
    };

    return (
        <React.Fragment>
            <div className="absolute-header">
                <Header />
                <motion.section
                    exit={{opacity: 0}}
                    className="text-gray-700 body-font relative"
                >
                    {data?.main_banners?.length > 0 && (
                        <Slider banners={data?.main_banners} />
                    )}
                    <motion.div variants={content} animate="animate" initial="initial">
                        <motion.div variants={inputs}>
                            {data?.products?.length > 0 && (
                                <HomeProducts products={data?.products} />
                            )}
                        </motion.div>
                        <motion.div variants={inputs}>
                            <Notice notices={data?.notices} />
                        </motion.div>
                        <motion.div variants={inputs}>
                            <Instagram />
                        </motion.div>
                    </motion.div>
                </motion.section>
                <Footer />
            </div>
        </React.Fragment>
    )
}