import React, { useState } from "react"
import Header from "../../sections/Header"
import Footer from "../../sections/Footer"
import ProductCard from "../../snippets/ProductCard";
import ProductList from "../../snippets/ProductList";
import useSWR from "swr";
import { motion } from "framer-motion";

export default (props) => {
  const params = new URLSearchParams(window.location.search);
  let category = params.get("category_id");

  const { data, mutate } = useSWR(`/product?product_category_id=${category ? category : ''}`);
  const products = data?.products;

  const [productsView, setProductsView] = useState('grid');

  const content = {
    animate: {
      transition: { staggerChildren: 0.1 },
    },
  };

  const inputs = {
    initial: { y: -20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  return (
      <div>
        <Header />
        <motion.section
            exit={{opacity: 0}}
            className="text-gray-700 body-font relative"
        >
          <motion.div variants={content} animate="animate" initial="initial">
            {/* Breadcrumb with image */}
            <div className="breadcrumb-with-image breadcrumb-with-image--type-3">
              {/* Image */}
            </div>
            {/* End shop breadcrumb */}
            {/* Collection */}
            <div className="collection">
              {/* Container */}
              <div className="container container--type-3">
                {/* Top filter */}
                <motion.div variants={inputs}>
                  <div className="collection__top-filter clearfix">
                    {/* Sort and view */}
                    <div className="collection__sort-and-view d-flex align-items-center">
                      {/* View */}
                      <ul className="products-view d-flex align-items-center">
                        <li><a href="#" className={productsView === 'list' ? 'open-tooltip' : 'active open-tooltip'} onClick={() => setProductsView('grid')}><span className="custom-tooltip">Grid large</span><i className="lnil lnil-grid-alt" /></a></li>
                        <li><a href="#" className={productsView === 'grid' ? 'open-tooltip' : 'active open-tooltip'} onClick={() => setProductsView('list')}><span className="custom-tooltip">List default</span><i className="lnil lnil-list-alt-1" /></a></li>
                      </ul>
                      {/* End view */}
                    </div>
                    {/* End sort and view */}
                    {/* Founded products */}
                    <div className="collection__founded-products">
                      총 <span>{products?.total}</span>개
                    </div>
                    {/* End founded products */}
                  </div>
                </motion.div>
                {/* End top filter */}
                {/* Products row */}
                <motion.div variants={inputs}>
                  <div className="row products-row">
                    {products && products?.total > 0 ? (
                        <React.Fragment>
                          {productsView === 'list' ? (
                              <React.Fragment>
                                {products?.data?.map((product, i) => {
                                  return (
                                      <ProductList
                                          key={product.id}
                                          {...product}
                                      />
                                  )
                                })}
                              </React.Fragment>
                          ) : (
                              <React.Fragment>
                                {products?.data?.map((product, i) => {
                                  return (
                                      <div className="col-6 col-lg-4" key={product.id}>
                                        <ProductCard
                                            key={product.id}
                                            {...product}
                                        />
                                      </div>
                                  )
                                })}
                              </React.Fragment>
                          )}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                          <div className="col-12"><div className="loading-spin text-center"><div className="loader"></div></div></div>
                        </React.Fragment>
                    )}
                  </div>
                </motion.div>
                {/* End Products row */}
              </div>
              {/* End container */}
            </div>
            {/* End collection */}
          </motion.div>
        </motion.section>
        <Footer />
      </div>
  )
}