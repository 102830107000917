import React, { useRef } from "react"
import 'lazysizes';
import Slider from "react-slick";

export default (props) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true
  };
  const sliderRef = useRef();
  return (
    <div>
      {/* Slider */}
      <div className="full-width-slider">
        {/* Slider js */}
        <Slider ref={sliderRef} {...settings}>
          {props?.banners?.map((banner, key) => (
              <>
                {/* Slider item */}
                <div className="full-width-slider__item js-slider-item" data-id={key + 1}>
                  {/* Image */}
                  <div className="full-width-slider__image">
                    <img alt="Image" data-sizes="auto" data-srcset={encodeURI(banner?.image_url)} src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload js-slider-item-image active" />
                  </div>
                  {/* End image */}
                  {/* Current slider number */}
                  <div className="full-width-slider__current-slider">
                    <span className="current-slider">{key + 1}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="37.438" height="34.624" viewBox="0 0 37.438 34.624">
                      <path id="Shape_2_copy" data-name="Shape 2 copy" className="cls-1" d="M109.534,903.679l0.678,0.734L73.466,938.322l-0.678-.735Z" transform="translate(-72.781 -903.688)" />
                    </svg>
                    <span>{props?.banners?.length}</span>
                  </div>
                  {/* End current slider number */}
                </div>
              </>
          ))}
        </Slider>
        {/* End slider js */}
      </div>
      {/* End slider */}
    </div>
  )
}