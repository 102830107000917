import React from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import Login from "../pages/user/Login"
import Join from "../pages/user/Join"
import FindId from "../pages/user/FindId"
import FindPassword from "../pages/user/Findpassword"
import Cart from "../pages/cart/Index"
import Present from "../pages/order/Present"
import Checkout from "../pages/order/Checkout"
import OrderComplete from "../pages/order/Complete"
import Order from "../pages/order/Index"
import OrderDetail from "../pages/order/Detail"
import Main from "../pages/main/Index"
import Product from "../pages/product/Index"
import ProductDetail from "../pages/product/Detail"
import ProductCheck from "../pages/product/Check"
import Mypage from "../pages/user/Mypage"
import Notice from "../pages/notice/Index"
import NoticeDetail from "../pages/notice/Detail"
import PrivacyTerms from "../pages/terms/Privacy"
import ServiceTerms from "../pages/terms/Service"
import ErrorPage from '../pages/404';
import { AnimatePresence } from "framer-motion";
import { SWRConfig } from "swr";
import { api } from "../utils/api";
import "../styles/font.css"

export default (props) => {
    const swrConfig = {
        fetcher(url, params) {
            return api.get(url, { params }).then((res) => {
                return res.data?.data || res.data;
            });
        },
    };

    return (
        <AnimatePresence>
            <SWRConfig value={swrConfig}>
                <Router>
                    <div id="app">
                        <Switch>
                            <Route exact path="/" render={() => <Redirect to="/main" />} />
                            <Route path="/main" component={Main} />
                            <Route path="/login" component={Login} />
                            <Route path="/join" component={Join} />
                            <Route path="/terms/privacy" component={PrivacyTerms} />
                            <Route path="/terms/service" component={ServiceTerms} />
                            <Route path="/find/id" component={FindId} />
                            <Route path="/find/password" component={FindPassword} />
                            <Route path="/cart" component={Cart} />
                            <Route path="/present/:code" component={Present} />
                            <Route path="/checkout" component={Checkout} />
                            <Route path="/order/complete" component={OrderComplete} />
                            <Route path="/order/index" component={Order} />
                            <Route path="/order/detail/:id" component={OrderDetail} />
                            <Route path="/mypage" component={Mypage} />
                            <Route path="/product/index" component={Product} />
                            <Route path="/product/detail/:id" component={ProductDetail} />
                            <Route path="/product/check/:type" component={ProductCheck} />
                            <Route path="/notice/index" component={Notice} />
                            <Route path="/notice/detail/:id" component={NoticeDetail} />
                            <Route component={ErrorPage} />
                        </Switch>
                    </div>
                </Router>
            </SWRConfig>
        </AnimatePresence>
    )
}
