import React from "react"
import Header from "../../sections/Header"
import Footer from "../../sections/Footer"
import useSWR from "swr";
import {Dot} from "../../utils/CommonFunction";
import { motion } from "framer-motion";

export default (props) => {
    const params = new URLSearchParams(window.location.search);
    let success = params.get("success");
    let orderId = params.get("order_id");
    let message = params.get("message");

    const content = {
        animate: {
            transition: { staggerChildren: 0.1 },
        },
    };

    const inputs = {
        initial: { y: -20, opacity: 0 },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.7,
                ease: [0.6, -0.05, 0.01, 0.99],
            },
        },
    };

    const { data: orderData } = useSWR(`/order/detail?order_id=${orderId}`);

    const order = orderData?.order;
    const orderProducts = orderData?.order_products;

    return (
        <div>
            <Header />
            <motion.section
                exit={{opacity: 0}}
                className="text-gray-700 body-font relative"
            >
                <motion.div variants={content} animate="animate" initial="initial">
                    {/* Order complete */}
                    <div className="order-complete">
                        {/* Container */}
                        <div className="container container--type-3">
                            {/* Container */}
                            <div className="container">
                                {/* Order complete container */}
                                <div className="order-complete__container">
                                    {/* Heading */}
                                    <div className="order-complete__heading">
                                        {/* H1 */}
                                        <motion.div variants={inputs}>
                                            <h1 className="order-complete__h1">{success === 'true' ? '주문이 완료되었습니다!' : '주문 실패하였습니다'}</h1>
                                        </motion.div>
                                        {/* End h1 */}
                                        {success === 'true' && (
                                            <div className="order-complete__order-number">
                                                <motion.div variants={inputs}>
                                                    order number
                                                    <p>{order?.code}</p>
                                                </motion.div>
                                            </div>
                                        )}
                                    </div>
                                    {/* End heading */}
                                    {/* Description */}
                                    <div className="order-complete__description">
                                        {success === 'true' ? (
                                            <>
                                                <motion.div variants={inputs}>
                                                    주문해주셔서 감사합니다 😍
                                                    {order?.payment_method === 'vbank' && (
                                                        <>
                                                            <br/>
                                                            입금 계좌 : {order?.vBankName} {order?.vBankNumber} {order?.vBankHolder} / 입금 마감 기한 : {order?.vBankExpDate}
                                                        </>
                                                    )}
                                                </motion.div>
                                            </>
                                        ) : message}
                                    </div>
                                    {/* End description */}
                                    {success === 'true' ? (
                                        <>
                                            <motion.div variants={inputs}>
                                                {/* Order summary */}
                                                <h3 className="order-complete__order-summary">주문 내역</h3>
                                                {/* End order summary */}
                                                {/* Products */}
                                                <div className="order-complete__products">
                                                    {/* Product */}
                                                    {orderProducts?.map((orderProduct) => (
                                                        <div className="order-complete__product">
                                                            <div className="order-complete-product__image">
                                                                <a href={`/product/detail/${orderProduct?.product_id}`}>
                                                                    <img alt="Image" src={orderProduct?.image_url} />
                                                                </a>
                                                            </div>
                                                            <div className="order-complete-product__title-and-variant">
                                                                <h3 className="order-complete-product__title"><a href={`/product/detail/${orderProduct?.product_id}`}>{orderProduct?.name}</a></h3>
                                                                <div className="order-complete-product__variant">
                                                                    {orderProduct?.option_name ? (<>옵션명 : {orderProduct?.option_name} <br/></>) : ''}
                                                                    {orderProduct?.bias_color_name ? (<>바이어스 컬러 : {orderProduct?.bias_color_name} <br/></>) : ''}
                                                                    {orderProduct?.use_personal === 1 && (<>텍스트 컬러 : {orderProduct?.text_color_name} / 폰트 : {orderProduct?.font} / 문구 : {orderProduct?.message}</>)}
                                                                </div>
                                                            </div>
                                                            <div className="order-complete-product__price">{Dot(orderProduct?.total_price) + '원'}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {/* End products */}
                                                {/* Subtotal and shipping */}
                                                <div className="order-complete__subtotal-and-shipping">
                                                    {/* Subtotal */}
                                                    <div className="order-complete__subtotal">
                                                        <div className="order-complete-subtotal__title">총 금액</div>
                                                        <div className="order-complete-subtotal__price">{Dot(order?.total_price) + '원'}</div>
                                                    </div>
                                                    <div className="order-complete__subtotal">
                                                        <div className="order-complete-subtotal__title">배송비</div>
                                                        <div className="order-complete-subtotal__price">{Dot(order?.delivery_fee) + '원'}</div>
                                                    </div>
                                                    {/* End subtotal */}
                                                    {/* Shipping */}
                                                    <div className="order-complete__shipping">
                                                        <div className="order-complete-shipping__title">쿠폰 할인 금액</div>
                                                        <div className="order-complete-shipping__price">{Dot(order?.coupon_price) + '원'}</div>
                                                    </div>
                                                    <div className="order-complete__shipping">
                                                        <div className="order-complete-shipping__title">적립금 사용 금액</div>
                                                        <div className="order-complete-shipping__price">{Dot(order?.point) + '원'}</div>
                                                    </div>
                                                    {/* End shipping */}
                                                </div>
                                                {/* End subtotal and shipping */}
                                                {/* Total */}
                                                <div className="order-complete__total">
                                                    <div className="order-complete-total__title">결제 금액</div>
                                                    <div className="order-complete-total__price">{Dot(order?.payment_price) + '원'}</div>
                                                </div>
                                                {/* End total */}
                                                {/* Action -*/}
                                                <div className="order-complete__action">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <a href="/order/index" className="fifth-button">주문 내역</a>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <a href="/product/index" className="third-button">계속 쇼핑하기</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* End action */}
                                            </motion.div>
                                        </>
                                    ) : (
                                        <>
                                            {/* Action -*/}
                                            <div className="order-complete__action">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <a href="/cart" className="fifth-button">재주문하기</a>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <a href="/product/index" className="third-button">계속 쇼핑하기</a>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End action */}
                                        </>
                                    )}
                                </div>
                                {/* End container */}
                            </div>
                            {/* End container */}
                        </div>
                        {/* End container */}
                    </div>
                    {/* End order complete */}
                </motion.div>
            </motion.section>
            <Footer />
        </div>
    )
}