import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import { api } from "../../utils/api";
import auth from "../../utils/auth";
import { motion } from "framer-motion";

export default (props) => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const content = {
    animate: {
      transition: { staggerChildren: 0.1 },
    },
  };

  const inputs = {
    initial: { y: -20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  const handleSubmit = async () => {
    if (email === "") {
      window.alert("이메일을 입력해주세요");
      return false;
    }

    if (password === "") {
      window.alert("비밀번호를 입력해주세요");
      return false;
    }

    const formdata = new FormData();
    formdata.append("email", email);
    formdata.append("password", password);

    const {
      data: { success, alert, data },
    } = await api.post("/login", formdata);

    if (success) {
      auth.setToken(data?.token?.access_token);
      auth.setRefreshToken(data?.token?.refresh_token);
      if (localStorage.getItem("token")) {

        if (localStorage.getItem("product_id")) {
          history.push("/product/detail/" + localStorage.getItem("product_id"));
        } else {
          history.push("/");
        }
        window.location.reload();
      }
    } else {
      window.alert(alert);
    }
  };

  return (
      <React.Fragment>
        <Header homepage="true" />
        <motion.section
            exit={{opacity: 0}}
            className="text-gray-700 body-font relative"
        >
          <motion.div variants={content} animate="animate" initial="initial">
            {/* Login */}
            <div className="login">
              {/* Container */}
              <div className="container container--type-2">
                {/* Container */}
                <div className="container">
                  {/* Login container */}
                  <div className="login__container">
                    {/* Login d-flex */}
                    <div className="login__d-flex d-flex">
                      {/* Login left */}
                      <div className="login__full">
                        {/* Login box */}
                        <div className="login__box active js-login-in">
                          {/* Login heading */}
                          <motion.div variants={inputs}>
                            <h4 className="login__h4">Login</h4>
                            {/* End login heading */}
                            {/* Login description */}
                            <p className="login__description">계정 정보를 입력해주세요</p>
                          </motion.div>
                          {/* End login description */}
                          {/* Form */}
                          <motion.div variants={inputs}>
                            <form>
                              {/* Form group */}
                              <div className="form-group required">
                                <input type="email" required="required" name="email" placeholder="Email" className="form-group__input" onChange={(e) => setEmail(e.target.value)} />
                              </div>
                              {/* End form group */}
                              {/* Form group */}
                              <div className="form-group required">
                                <input type="password" required="required" name="password" placeholder="Password" className="form-group__input" onChange={(e) => setPassword(e.target.value)} />
                              </div>
                              {/* End form group */}
                              {/* Join */}
                              {/* Forgot password */}
                              <div className="login__text_box">
                                <div className="login__join">
                                  <a href="/join" className="js-login-join">회원가입</a>
                                </div>
                                <div className="login__forgot-password">
                                  <a href="/find/id" className="js-login-forgot-password">아이디 찾기</a>
                                  <span className="js-login-forgot-password"> / </span>
                                  <a href="/find/password" className="js-login-forgot-password">비밀번호 찾기</a>
                                </div>
                              </div>
                              {/* End forgot password */}
                              {/* Action */}
                              <div className="login__action"><input className="second-button" type="button" value="Login" onClick={handleSubmit} /></div>
                              {/* End action */}
                            </form>
                          </motion.div>
                          {/* End form */}
                        </div>
                        {/* End login box */}
                      </div>
                      {/* End login left */}
                    </div>
                    {/* End login d-flex */}
                  </div>
                  {/* End login container */}
                </div>
                {/* End container */}
              </div>
              {/* End container */}
            </div>
          </motion.div>
        </motion.section>
        {/* End login */}
        <Footer />
      </React.Fragment>
  )
}