import React, {useState} from 'react';
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import { Link } from 'react-router-dom';
import useSWRInfinite from "swr/infinite";
import moment from "moment";
import { api } from "../../utils/api";
import { motion } from "framer-motion";

export default (props) => {
    const [lastPage, setLastPage] = useState(0);

    const { data: notices, size, setSize } = useSWRInfinite(
        (pageIndex) => {
            return `/notice?page=${pageIndex + 1}`;
        },
        async (url, params) => {
            const res = await api.get(url, { params });

            setLastPage(res?.data?.data?.notices?.last_page);
            return res?.data?.data?.notices?.data || res.data;
        }
    );

    const content = {
        animate: {
            transition: { staggerChildren: 0.1 },
        },
    };

    const inputs = {
        initial: { y: -20, opacity: 0 },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.7,
                ease: [0.6, -0.05, 0.01, 0.99],
            },
        },
    };

    return (
        <React.Fragment>
            <Header />
            <motion.section
                exit={{opacity: 0}}
                className="text-gray-700 body-font relative"
            >
                <motion.div variants={content} animate="animate" initial="initial">
                    {/* Blog page */}
                    <div className="blog">
                        {/* Container */}
                        <div className="container container--type-3">
                            {/* Title */}
                            <h1 className="blog__title">정보 전달 게시판</h1>
                            {/* End title */}
                            {/* Description */}
                            <div className="blog__description"></div>
                            {/* End description */}
                            {/* Container */}
                            <div className="container">
                                {/* Categories and search */}
                                <motion.div variants={inputs}>
                                    <div className="blog__categories-and-search">
                                        {/* Categories */}
                                        <ul className="blog__categories">
                                            <li><Link to="/notice/index" className="active">All</Link></li>
                                        </ul>
                                        {/* End categories */}
                                    </div>
                                </motion.div>
                                {/* End Categories and search */}
                                {/* Blog articles */}
                                <motion.div variants={inputs}>
                                    <div className="blog__articles row">
                                        {notices && notices?.map((noticeSection) =>
                                            noticeSection?.map((notice) => (
                                                <>
                                                    {/* Article */}
                                                    <div className="col-lg-4">
                                                        <div className="blog-article">
                                                            {/* Image */}
                                                            <div className="blog-article__image">
                                                                <Link to={`/notice/detail/${notice?.id}`}>
                                                                    <img alt="Image" data-sizes="auto" data-srcset={notice?.thumbnail_image_url} className="lazyload" />
                                                                </Link>
                                                            </div>
                                                            {/* End image */}
                                                            {/* Meta */}
                                                            <ul className="blog-article__meta">
                                                                <li>{moment(notice?.created_at).format('YYYY-MM-DD')}</li>
                                                            </ul>
                                                            {/* End meta */}
                                                            {/* Title */}
                                                            <h5 className="blog-article__title">
                                                                <Link to={`/notice/detail/${notice?.id}`}>{notice?.title}</Link>
                                                            </h5>
                                                            {/* End Title */}
                                                        </div>
                                                    </div>
                                                    {/* End article */}
                                                </>
                                            ))
                                        )}
                                    </div>
                                    {/* End Blog articles */}
                                    {/* Load more */}
                                    {lastPage > size && (
                                        <div className="blog__load-more">
                                            <a className="sixth-button" onClick={() => setSize(size + 1)}>더 보기</a>
                                        </div>
                                    )}
                                    {/* End load more */}
                                </motion.div>
                            </div>
                            {/* End container */}
                        </div>
                        {/* End container */}
                    </div>
                    {/* End Blog page */}
                </motion.div>
            </motion.section>
            <Footer />
        </React.Fragment>
    )
}