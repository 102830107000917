import React from "react"
import useSWR from "swr";

export default (props) => {
    const { data, mutate } = useSWR(`/footer`);
    const footers = data?.footers;

    return (
        <div>
            {/* Footer */}
            <footer className="sidebar-footer">
                {/* Container */}
                <div className="container container--type-3">
                    {/* Copyright */}
                    <div className="sidebar-footer__copyright">
                        © 2022 <span>OUIMER.</span> all rights reserved <br/>
                        <div className="footer-info">
                            {footers?.map((footer, key) => (
                                <>
                                    <span>{footer?.name} : {footer?.value}</span>
                                </>
                            ))}
                        </div>
                    </div>
                    {/* End copyright */}
                </div>
                {/* End container */}
            </footer>
            {/* End footer */}
        </div>
    )
}