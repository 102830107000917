import React from "react"
import { Link } from 'react-router-dom';
import auth from "../utils/auth";

export default (props) => {
  return (
      <React.Fragment>
        {/* Canvas menu */}
        <div className={props?.activeMenu ? 'canvas-menu active' : 'canvas-menu'}>
          <div className="canvas-menu__overlay" onClick={() => {props.setActiveMenu(false)}} />
          {/* Content */}
          <div className="canvas-menu__content">
            {/* Close */}
            <div className="canvas-menu__close"><a onClick={() => {props.setActiveMenu(false)}}><i className="lnil lnil-close" /><span>Close</span></a></div>
            {/* End close */}
            {/* Switchers */}
            <div className="canvas-menu__switchers">
            </div>
            {/* End switchers */}
            {/* Navigation */}
            <ul className="canvas-menu__nav">
              <li>
                <Link to="/main" className="canvas-nav__item" onClick={() => {props.setActiveMenu(false)}}>MAIN</Link>
              </li>
              <li>
                <Link to="/product/index" className="canvas-nav__item" onClick={() => {props.setActiveMenu(false)}}>ALL</Link>
              </li>
              {props?.product_categories?.map((product_category) => (
                  <li>
                    <Link to={`/product/index?category_id=${product_category?.id}`} className="canvas-nav__item" onClick={() => {props.setActiveMenu(false)}}>{product_category?.category}</Link>
                  </li>
              ))}
            </ul>
            {/* End navigation */}
            {/* About and social */}
            <div className="canvas-menu__about-and-social">
              <div className="sidebar-header__account">
                <i className="lnil lnil-user" />
                { auth.getToken() ? (
                    <>
                      <Link to="/mypage">내 정보</Link>
                      <Link to="/order/index">주문 내역</Link>
                      <Link to="" onClick={() => { auth.clearAppStorage(); window.location.reload(); }}>로그아웃</Link>
                    </>
                ) : (
                    <>
                      <Link to="/login">로그인</Link>
                      <Link to="/join">회원가입</Link>
                    </>
                )}
              </div>
            </div>
            {/* End about and social */}
          </div>
          {/* End content */}
        </div>
        {/* End canvas menu */}
      </React.Fragment>
  )
}