import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import { api } from "../../utils/api";
import { checkPhonenumber, checkEmail } from "../../utils/CommonFunction";
import { motion } from "framer-motion";

export default (props) => {
  const history = useHistory();

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [result, setResult] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const content = {
    animate: {
      transition: { staggerChildren: 0.1 },
    },
  };

  const inputs = {
    initial: { y: -20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  useEffect(() => {
    if (phone.length === 10 || phone.length === 11) {
      setPhone(phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
    }
    if (phone.length === 13) {
      setPhone(phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    }
  }, [phone]);

  const handleSubmit = async () => {
    if (email === "" || phone === "") {
      window.alert("필수 항목을 입력해주세요");
      return false;
    }

    if (!checkPhonenumber(phone)) {
      window.alert("휴대폰 번호 형식을 재확인 해주세요");
      return false;
    }

    setButtonDisabled(true)

    const formdata = new FormData();
    formdata.append("email", email);
    formdata.append("phone", phone);

    const {
      data: { success, alert, data },
    } = await api.post("/find/password", formdata);

    if (success) {
      setButtonDisabled(false)
      window.alert('메일로 임시 비밀번호가 발송되었습니다.')
      history.push('/login')
    } else {
      setButtonDisabled(false)
      window.alert(alert);
    }
  };

  return (
      <React.Fragment>
        <Header homepage="true" />
        <motion.section
            exit={{opacity: 0}}
            className="text-gray-700 body-font relative"
        >
          <motion.div variants={content} animate="animate" initial="initial">
            {/* Login */}
            <div className="login">
              {/* Container */}
              <div className="container container--type-2">
                {/* Container */}
                <div className="container">
                  {/* Login container */}
                  <div className="login__container">
                    {/* Login d-flex */}
                    <div className="login__d-flex d-flex">
                      {/* Login left */}
                      <div className="login__full">
                        {/* Login box */}
                        <div className="login__box active js-login-in">
                          <>
                            {/* Login heading */}
                            <motion.div variants={inputs}>
                              <h4 className="login__h4">비밀번호 찾기</h4>
                              {/* End login heading */}
                              {/* Login description */}
                              <p className="login__description">정보를 입력해주세요</p>
                            </motion.div>
                            {/* End login description */}
                            {/* Form */}
                            <motion.div variants={inputs}>
                              <form>
                                {/* Form group */}
                                <div className="form-group required">
                                  <input type="text" required="required" name="email" placeholder="Email" className="form-group__input" value={email} onChange={(e) => { setEmail(e?.target?.value?.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '')) }} />
                                </div>
                                {/* End form group */}
                                {/* Form group */}
                                <div className="form-group required">
                                  <input type="text" required="required" name="phone" placeholder="Phone" className="form-group__input" value={phone} onChange={(e) => {
                                    const regex = /^[0-9\b -]{0,13}$/;
                                    if (regex.test(e.target.value)) {
                                      setPhone(e.target.value);
                                    }
                                  }} />
                                </div>
                                {/* End form group */}
                                {/* Action */}
                                <div className="login__action"><input className="second-button" type="button" value="비밀번호 찾기" disabled={buttonDisabled} onClick={handleSubmit} /></div>
                                {/* End action */}
                              </form>
                            </motion.div>
                            {/* End form */}
                          </>
                        </div>
                        {/* End login box */}
                      </div>
                      {/* End login left */}
                    </div>
                    {/* End login d-flex */}
                  </div>
                  {/* End login container */}
                </div>
                {/* End container */}
              </div>
              {/* End container */}
            </div>
          </motion.div>
        </motion.section>
        {/* End login */}
        <Footer />
      </React.Fragment>
  )
}