import React, { useState } from "react"
import { Link } from 'react-router-dom';
import Logo from "../snippets/Logo"
import MobileNav from "../snippets/MobileNav"
import MainNav from "../snippets/MainNav"
import useSWR from "swr";
import auth from "../utils/auth";

export default (props) => {
    const [activeMenu, setActiveMenu] = useState(false);

    const { data, mutate } = useSWR(`/gnb`);

    const product_categories = data?.product_categories;
    const cart = data?.cart;

    return (
        <div>
            {/* Header */}
            <header className="header header--type-2">
                {/* Container */}
                <div className="container container--type-3">
                    {/* Header container */}
                    <div className="header__container d-flex align-items-center">
                        <MobileNav product_categories={product_categories} />
                        <Logo />
                        {/* Header right */}
                        <ul className="header__right">
                            <li><Link to={auth.getToken() ? '/mypage' : '/login'}><i className="lnil lnil-user" /></Link></li>
                            <li className="header__cart">
                                <a href='/cart'><i className="lnil lnil-cart" /><span>{cart}</span></a>
                            </li>
                            <li className="header__canvas-menu"><a onClick={() => { setActiveMenu(true) }}><i className="lnil lnil-menu" /></a></li>
                        </ul>
                        {/* End header right */}
                    </div>
                    {/* End header container */}
                </div>
                {/* End container */}
            </header>
            {/* End header */}
            <MainNav activeMenu={activeMenu} setActiveMenu={setActiveMenu} product_categories={product_categories} />
        </div>
    )
}