import React from "react"
import ReactDOM from "react-dom"
import App from "./app/App"
import "./styles/main.scss"

ReactDOM.render(
    <>
        <App />
    </>,
    document.getElementById("root")
)
