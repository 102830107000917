import React, { useEffect } from 'react';
import Header from "../sections/Header";
import Footer from "../sections/Footer";
import { Link } from 'react-router-dom';

export default (props) => {
  return (
    <React.Fragment>
      <Header homepage="true" />
      {/* Not found page */}
      <div className="not-found-page">
        {/* Container */}
        <div className="container container--type-2">
          {/* Title */}
          <h1 className="not-found-page__title">404</h1>
          {/* End title */}
          {/* Description */}
          <div className="not-found-page__description">존재하지 않는 페이지 입니다</div>
          {/* End description */}
          {/* Action */}
          <div className="not-found-page__action">
            <Link to="/" className="fourth-button">홈으로 가기</Link>
          </div> 
          {/* End action */}
        </div>
        {/* End Container */}
      </div>
      {/* End not found page */}
      <Footer />
    </React.Fragment>
  )
}