import React from "react"
import { Link } from 'react-router-dom';

export default (props) => {
  return (
    <div>
      {/* Instagram */}
      <div className="instagram">
        {/* Container */}
        <div className="container container--type-2">
          {/* Second container */}
          <div className="container">
            {/* Line 1 px */}
            <hr />
            {/* End line 1 px */}
            {/* Title */}
            <h5 className="instagram__title">Follow our instagram</h5>
            {/* End title */}
            {/* Description */}
            <div className="instagram__description">Our official instagram <a href="#">@ouimer.official</a></div>
            {/* End description */}
            {/* Instagram feed */}
            <ul className="instagram_feed">
              {/* Feed */}
              <iframe src="https://cdn.lightwidget.com/widgets/0597db83cf6e54f7b60e15601e04b5fb.html" scrolling="no"
                      allowTransparency="true" className="lightwidget-widget"
                      style={{ width: "100%", border: 0, overflow: "hidden" }}/>
              {/* End feed */}
            </ul>
            {/* Instagram feed */}
          </div> 
          {/* End second container */}
        </div>
        {/* End container */}
      </div>
      {/* End instagram */}
    </div>
  )
}